import { AuthApi } from "@api/AuthApi";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { Popover } from "@components/Popover";
import { Button } from "@components/Button";
import { ReactComponent as DropdownArrowImg } from "@img/dropdown_arrow.svg";
import { ReactComponent as AccountImg } from "@img/account.svg";
import { ReactComponent as LogOutImg } from "@img/log_out.svg";
import cs from "./Account.module.scss";

export const Account = () => {
  const { currentUser } = useCurrentUser();

  return (
    <Popover
      popover={
        <div>
          <section className={cs.emailSection}>
            <div title={currentUser?.email} className={cs.email}>
              {currentUser?.email}
            </div>
          </section>

          <section className={cs.footer}>
            <Button color="secondary" className={cs.logOutBtn} onClick={logOut}>
              <LogOutImg width={14} className="mr6" />

              <span>Wyloguj się</span>
            </Button>

            <div className={cs.footerInfo}>
              <span>up4edu | </span>
              <a href="https://www.up4edu.com/privacy" target="_blank">
                Polityka prywatności
              </a>
            </div>

            <div className={cs.footerInfo}>
              <span>Kontakt: </span>
              <a href="mailto:contact@up4edu.com">contact@up4edu.com</a>
            </div>
          </section>
        </div>
      }
      square
      align="end"
      className={cs.popover}
    >
      <span className={cs.user}>
        <AccountImg className={cs.accountIcon} />

        <span className="hide-desktop-down">
          {currentUser?.firstName} {currentUser?.lastName}
        </span>

        <DropdownArrowImg width={12} className="hide-desktop-down" />
      </span>
    </Popover>
  );

  async function logOut() {
    await AuthApi.logOut();

    window.location.href = "/login";
  }
};
