import axios from "axios";
import { camelizeKeys } from "humps";

export function createAxiosInstance(baseURL: string) {
  const instance = axios.create({
    baseURL,
  });

  instance.interceptors.response.use(function (response) {
    if (
      response.data &&
      response.headers["content-type"]?.includes("application/json")
    ) {
      response.data = camelizeKeys(response.data, (key, convert) => {
        return key === "_id" ? key : convert(key);
      });
    }
    return response;
  });

  instance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (error.response) {
        return Promise.reject({
          status: error.response.status,
          message: error.response.data?.error?.message,
          error: error,
        });
      }

      return Promise.reject(error);
    },
  );

  return instance;
}
