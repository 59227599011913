import { Popover } from "../Popover";
import cs from "./Dropdown.module.scss";
import React, { useRef } from "react";

type DropdownProps = {
  title?: string;
  options: React.ReactElement[];
  children: React.ReactNode;
};

export const Dropdown = ({ title, options, children }: DropdownProps) => {
  const popoverEl = useRef<any>(null);

  const dropdown = (
    <div>
      {title && <div className={cs.title}>{title}</div>}

      <div className={cs.options}>
        {options.map((option, index) => (
          <div
            key={index}
            className={cs.option}
            onClick={() => {
              popoverEl.current.close();
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Popover
      position={["bottom", "top", "right", "left"]}
      popover={dropdown}
      className={cs.root}
      ref={popoverEl}
    >
      {children}
    </Popover>
  );
};
