import { CurrentUserType } from "@customTypes/CurrentUserType";
import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/auth");

export class AuthApi {
  static async fetchCurrentUser() {
    const { data } = await api.get<CurrentUserType>("");

    return data;
  }

  static async replaceCurrentUser({ currentUser }: { currentUser: any }) {
    return api.put("", currentUser);
  }

  static async register(form: any) {
    return api.post("/register", form);
  }

  static async logIn({ email, password }: { email: string; password: string }) {
    return api.post("/login", { email, password });
  }

  static async logOut() {
    return api.post("/logout");
  }

  static async updatePassword({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }) {
    return api.put("/password", { currentPassword, newPassword });
  }

  static async sendResetPasswordEmail({ email }: { email: string }) {
    return api.post("/password/reset/" + email);
  }

  static async resetPassword({
    password,
    token,
  }: {
    password: string;
    token: string;
  }) {
    return api.put("/password/reset/", { password, token });
  }
}
