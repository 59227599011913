import { useMutation, useQueryClient } from "react-query";
import { LessonApi } from "@api/LessonApi";

export const useUploadHomework = () => {
  const queryClient = useQueryClient();

  return useMutation(LessonApi.uploadHomework, {
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: ["lessons"],
        refetchInactive: true,
      });

      queryClient.invalidateQueries({
        queryKey: ["calendar"],
        refetchInactive: true,
      });
    },
  });
};
