import { toast } from "react-toastify";

export class ToastService {
  static success(text: string, options?: { duration: number }) {
    toast.success(text, {
      position: "top-right",
      autoClose: options?.duration || 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  static error(text: string, options?: { duration: number }) {
    toast.error(text, {
      position: "top-right",
      autoClose: options?.duration || 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
      theme: "light",
    });
  }

  static generalError() {
    ToastService.error(
      "Wystąpił błąd. Zostaliśmy o nim poinformowani. Prosimy spróbować ponownie później.",
      { duration: 5000 },
    );
  }
}
