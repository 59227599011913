import { Dropdown } from "@components/Dropdown";
import moreImg from "./img/more.svg";

type ColumnType = {
  onDeleteTutor: (tutorId: string) => void;
};

export const columns = ({ onDeleteTutor }: ColumnType) => [
  {
    title: "Imię i nazwisko",
    field: "name",
  },
  {
    title: "E-mail",
    field: "email",
    type: "email",
  },
  {
    title: "Telefon",
    field: "phone",
    type: "phone",
  },
  //   TODO: Later
  // {
  //   title: "",
  //   field: "_id",
  //   width: 100,
  //   center: true,
  //   render(value: any) {
  //     const dropdownOptions = [
  //       <div onClick={() => onDeleteTutor(value)}>
  //         Zrezygnuj ze wspólnej nauki
  //       </div>,
  //     ];
  //
  //     return (
  //       <Dropdown title="Akcje:" options={dropdownOptions}>
  //         <button className="btn-icon" style={{ verticalAlign: "middle" }}>
  //           <img src={moreImg} alt="" />
  //         </button>
  //       </Dropdown>
  //     );
  //   },
  // },
];
