import {
  diff,
  formatDate,
  formatShortDate,
  formatWeekday,
  toRelative,
} from "@utils/date.utils";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { LessonDate } from "@pages/LessonsListPage/columns";
import { PaymentStatus } from "@components/PaymentStatus";
import { ReactComponent as CheckImg } from "./img/check.svg";
import { ReactComponent as CancelImg } from "./img/cancel.svg";

type PropsType = {
  onStatusChange: (arrearId: string, status: LessonPaymentStatus) => void;
};

export const columnsMobile = ({ onStatusChange }: PropsType) => [
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 160,
    render(field: any, col: any, row: StandaloneArrearType) {
      return (
        <div>
          <div
            style={{
              fontWeight: "600",
              marginLeft: "20px",
              marginBottom: "3px",
            }}
          >
            {row.lesson.tutor.shortName}
          </div>
          <div>
            <LessonDate lesson={row.lesson} />
          </div>
        </div>
      );
    },
  },
  {
    title: "Status",
    field: "status",
    width: 140,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      const deadline = row.lesson.paymentDeadline;
      const fullDate = formatDate(deadline, "full");
      const daysAfterDeadline = diff(new Date(), deadline);
      const rootCs = row.isAfterDeadline ? "red" : "";

      const relativeTime = toRelative(deadline);

      let deadlineEl = null;

      if (row.status !== LessonPaymentStatus.AWAITS || !deadline) {
        deadlineEl = null;
      } else if (daysAfterDeadline < -4) {
        const date = formatShortDate(deadline);
        const weekday = formatWeekday(deadline);

        deadlineEl = (
          <div title={fullDate} style={{ fontWeight: "500", fontSize: "13px" }}>
            {date}, {weekday}
          </div>
        );
      } else {
        deadlineEl = (
          <div
            className={rootCs}
            title={fullDate}
            style={{ fontWeight: "500", fontSize: "13px" }}
          >
            {relativeTime}
          </div>
        );
      }

      return (
        <div>
          {row.lesson.price && (
            <div style={{ fontSize: "13px", fontWeight: "500" }}>
              {row.lesson.price} zł
            </div>
          )}

          {deadlineEl}

          <PaymentStatus arrears={[row]} />
        </div>
      );
    },
  },
  {
    title: "",
    field: "status",
    width: 100,
    center: true,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      if (field === LessonPaymentStatus.CLOSED) {
        return (
          <button
            className="btn-icon"
            title="Oznacz jako oczekującą płatności"
            onClick={() => onStatusChange(row._id, LessonPaymentStatus.AWAITS)}
          >
            <CancelImg width={16} />
          </button>
        );
      }

      return (
        <button
          className="btn-icon"
          title="Oznacz jako rozliczoną"
          onClick={() => onStatusChange(row._id, LessonPaymentStatus.CLOSED)}
        >
          <CheckImg width={18} />
        </button>
      );
    },
  },
];
