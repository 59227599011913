import React from "react";
import classNames from "classnames";
import { Spinner } from "../Spinner";
import cs from "./Button.module.scss";

type ButtonProps = {
  color: "danger" | "primary" | "secondary" | "neutral" | "dark";
  size?: "default" | "small";
  type?: "outlined" | "normal";
  htmlType?: "button" | "reset" | "submit";
  className?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
};

export const Button = ({
  color,
  size = "default",
  type = "normal",
  htmlType = "button",
  className,
  children,
  isLoading = false,
  onClick = () => {},
}: ButtonProps) => {
  const rootCs = classNames(cs.root, cs[color], cs[type], cs[size], className);

  return (
    <Spinner loading={isLoading} className="Button">
      <button className={rootCs} type={htmlType} onClick={onClick}>
        {children}
      </button>
    </Spinner>
  );
};
