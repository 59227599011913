import cs from "./LessonBulb.module.scss";
import classNames from "classnames";

type LessonBulbProps = {
  past: boolean;
  size?: "small" | "default";
  className?: string;
  /**
   * For more precise vertical alignment
   */
  top?: number;
};

export const LessonBulb = ({
  past,
  size = "default",
  className,
  top = 0,
}: LessonBulbProps) => {
  const rootCs = classNames(cs.future, [cs[size]], className, {
    [cs.past]: past,
  });

  return <span className={rootCs} style={{ top }} />;
};
