import { useEffect, useState } from "react";
import { Info } from "luxon";
import { CalendarDates } from "@utils/calendarMatrix";
import { MonthViewDay } from "./MonthViewDay";
import { SingleDayModal } from "./SingleDayModal";
import cs from "./MonthView.module.scss";

export type MatrixDay = {
  date: number;
  iso: string;
  type: "previous" | "current" | "next";
};

type MatrixWeek = MatrixDay[];
type MatrixType = MatrixWeek[];

type MonthViewProps = {
  currentDate: Date;
  events: any;
};

const calendarDates = new CalendarDates();

const WEEKDAYS = Info.weekdays("short").map((weekday) =>
  weekday.replace(".", "").toUpperCase(),
);

export const MonthView = ({ currentDate, events }: MonthViewProps) => {
  const [matrix, setMatrix] = useState<MatrixType>([]);
  const [isDayModalOpen, setIsDayModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(null);

  useEffect(() => {
    calendarDates.getMatrix(new Date(currentDate)).then(setMatrix);
  }, [currentDate]);

  return (
    <div className={cs.root}>
      <div className={cs.week}>
        {WEEKDAYS.map((weekday) => (
          <div key={weekday} className={cs.weekDay}>
            {weekday}
          </div>
        ))}
      </div>

      <div>
        {matrix.map((week) => (
          <div className={cs.week}>
            {week.map((day) => (
              <MonthViewDay
                key={day.iso}
                day={day}
                events={events}
                onDayClicked={handleDayClicked}
              />
            ))}
          </div>
        ))}
      </div>

      <SingleDayModal
        isOpen={isDayModalOpen}
        selectedDay={selectedDay}
        onSelectedDayChange={setSelectedDay}
        onClose={() => setIsDayModalOpen(false)}
      />
    </div>
  );

  function handleDayClicked(day: Date) {
    setSelectedDay(day);
    setIsDayModalOpen(true);
  }
};
