//@ts-nocheck
import { downloadFile } from "@utils/utils";
import { formatShortDate, formatWeekday } from "@utils/date.utils";
import { TutorType } from "@customTypes/TutorType";
import { LessonType } from "@customTypes/LessonType";
import { LessonBulb } from "@components/LessonBulb";
import { ReactComponent as DownloadImg } from "@img/download.svg";
import cs from "./LessonsListPage.module.scss";

// TODO:
export const LessonDate = ({ lesson }) => {
  const shortDate = formatShortDate(lesson.date);
  const weekday = formatWeekday(lesson.date, "short");

  return (
    <div className={cs.LessonDate}>
      <LessonBulb past={lesson.isPast} size="default" />

      <div>
        <div>
          {shortDate}, {weekday}
        </div>
        <div className={cs.LessonDate_hours}>
          {lesson.startHourStr}-{lesson.endHourStr}
        </div>
      </div>

      <div></div>
    </div>
  );
};

export const columns = [
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 160,
    render(value: any, col: any, row: LessonType) {
      if (!value) return "-";

      return <LessonDate lesson={row} />;
    },
  },
  {
    title: "Tutor",
    field: "tutor",
    width: 180,
    hideWidth: 700,
    render(field: TutorType) {
      return <span>{field.shortName}</span>;
    },
  },
  {
    title: "Temat",
    field: "topic",
    hideWidth: 600,
  },
  {
    title: "Zad. dom.",
    field: "homeworks",
    center: true,
    width: 119,
    render(homeworks: LessonType["homeworks"]): JSX.Element {
      return (
        <span
          className={cs.homeworkCol}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            homeworks.forEach((homework) => {
              if (homework.type !== "link") {
                downloadFile(homework.link);
              }
            });
          }}
        >
          <span className="semi-bold mr8">{homeworks.length}</span>

          <button
            className="btn-icon"
            type="button"
            style={{ opacity: homeworks.length > 0 ? 1 : 0 }}
          >
            <DownloadImg width={12} style={{ color: "#E99455" }} />
          </button>
        </span>
      );
    },
  },
  {
    title: "Materiały",
    field: "materials",
    center: true,
    width: 119,
    hideWidth: 420,
    render(materials: LessonType["materials"]): JSX.Element {
      return (
        <span
          className={cs.homeworkCol}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            materials.forEach((material) => {
              if (material.type !== "link") {
                downloadFile(material.link);
              }
            });
          }}
        >
          <span className="semi-bold mr8">{materials.length}</span>

          <button
            className="btn-icon"
            type="button"
            style={{ opacity: materials.length > 0 ? 1 : 0 }}
          >
            <DownloadImg width={12} style={{ color: "#197BBD" }} />
          </button>
        </span>
      );
    },
  },
];
