import {
  diff,
  formatDate,
  formatShortDate,
  formatWeekday,
  toRelative,
} from "@utils/date.utils";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";
import { LessonType } from "@customTypes/LessonType";
import { LessonDate } from "@pages/LessonsListPage/columns";
import { PaymentStatus } from "@components/PaymentStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { ReactComponent as CheckImg } from "./img/check.svg";
import { ReactComponent as CancelImg } from "./img/cancel.svg";

type PropsType = {
  onStatusChange: (arrearId: string, status: LessonPaymentStatus) => void;
};

export const columns = ({ onStatusChange }: PropsType) => [
  {
    title: "Data lekcji",
    field: "date",
    sortable: true,
    width: 160,
    render(field: any, col: any, row: StandaloneArrearType) {
      return <LessonDate lesson={row.lesson} />;
    },
  },
  {
    title: "Tutor",
    field: "lesson",
    width: 180,
    render(lesson: LessonType) {
      return <span>{lesson.tutor.shortName}</span>;
    },
  },
  {
    title: "Status",
    field: "status",
    help: "To jest jakieś wytłumaczenie statusów",
    width: 140,
    render(
      field: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      return <PaymentStatus arrears={[row]} />;
    },
  },
  {
    title: "Termin",
    width: 140,
    field: "paymentDeadline",
    sortable: true,
    render(field: any, col: any, row: StandaloneArrearType) {
      const deadline = row.lesson.paymentDeadline;

      if (row.status !== LessonPaymentStatus.AWAITS || !deadline) {
        return <span>-</span>;
      }

      const fullDate = formatDate(deadline, "full");
      const daysAfterDeadline = diff(new Date(), deadline);

      // NOTE: Four days or more to deadline
      if (daysAfterDeadline < -4) {
        const date = formatShortDate(deadline);
        const weekday = formatWeekday(deadline);

        return (
          <span title={fullDate} style={{ cursor: "help" }}>
            {date}, {weekday}
          </span>
        );
      }

      const rootCs = row.isAfterDeadline ? "red" : "";

      const relativeTime = toRelative(deadline);

      return (
        <span className={rootCs} title={fullDate} style={{ cursor: "help" }}>
          {relativeTime}
        </span>
      );
    },
  },
  {
    title: "Cena",
    width: 120,
    render(field: any, col: any, row: StandaloneArrearType) {
      return <span>{row.lesson.price || "-"} zł</span>;
    },
  },
  {
    title: "",
    field: "status",
    width: 100,
    center: true,
    render(
      value: StandaloneArrearType["status"],
      col: any,
      row: StandaloneArrearType,
    ) {
      if (value === LessonPaymentStatus.PAID) {
        return (
          <button
            className="btn-icon"
            title="Oznacz jako oczekującą płatności"
            onClick={() => onStatusChange(row._id, LessonPaymentStatus.AWAITS)}
          >
            <CancelImg width={16} />
          </button>
        );
      }

      if (value === LessonPaymentStatus.AWAITS) {
        return (
          <button
            className="btn-icon"
            title="Oznacz jako opłaconą"
            onClick={() => onStatusChange(row._id, LessonPaymentStatus.PAID)}
          >
            <CheckImg width={18} />
          </button>
        );
      }

      return null;
    },
  },
];
