import React, { ReactNode } from "react";
import classNames from "classnames";
import { ClipLoader } from "react-spinners";
import cs from "./Spinner.module.scss";

type SpinnerProps = {
  loading?: boolean;
  className?: string;
  size?: number;
  children?: ReactNode;
};

export const Spinner = ({
  loading = true,
  className,
  size = 24,
  children = null,
}: SpinnerProps) => {
  const rootCs = classNames(className, cs.root);

  return (
    <div className={rootCs}>
      {children}

      {loading && (
        <>
          <div className={`Spinner ${cs.spinner}`}>
            <ClipLoader color="#ffffff" size={size} />
          </div>

          <div className={`Spinner_backdrop ${cs.backdrop}`} />
        </>
      )}
    </div>
  );
};
