import { ArrearDtoService } from "./ArrearDtoService";
import { LessonDtoService } from "./LessonDtoService";
import { StandaloneArrearType } from "../types/StandaloneArrearType";
import { StandaloneArrearDtoType } from "../types/StandaloneArrearDtoType";

export class StandaloneArrearDtoService {
  static transformAll(arrearDtos: StandaloneArrearDtoType[]) {
    return arrearDtos.map(StandaloneArrearDtoService.transform);
  }

  static transform(arrearDto: StandaloneArrearDtoType): StandaloneArrearType {
    const copy = { ...arrearDto };

    // NOTE: Related lesson dto
    copy.lesson = LessonDtoService.transform(copy.lesson);

    // NOTE: Arrear itself
    return ArrearDtoService.transform(
      copy,
      copy.lesson,
    ) as StandaloneArrearType;
  }
}
