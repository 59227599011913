import * as Sentry from "@sentry/react";
import { ToastContainer } from "react-toastify";
import { useAppStore } from "@store";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { Spinner } from "@components/Spinner";
import { ConfirmationModal } from "@components/ConfirmationModal";
import { ApplicationRoutes } from "./router/ApplicationRoutes";

initSentry();

const App = () => {
  const confirmationModal = useAppStore((state) => state.confirmationModal);

  const { isLoading } = useCurrentUser();

  if (isLoading) {
    return <Spinner loading className="fullViewSpinner" size={54} />;
  }

  return (
    <div className="App">
      <ApplicationRoutes />

      <ToastContainer />

      <ConfirmationModal
        isOpen={confirmationModal.isOpen}
        text={confirmationModal.text}
        confirmText={confirmationModal.confirmText}
        onCancel={confirmationModal.onCancel}
        onConfirm={confirmationModal.onConfirm}
      />
    </div>
  );
};

export default App;

function initSentry() {
  if (process.env.REACT_APP_ENV === "local") return;

  Sentry.init({
    dsn: "https://e5d9d9725e0742539dc2489010363816@o4504570743160833.ingest.sentry.io/4504571004059648",
    environment: process.env.REACT_APP_ENV,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    beforeSend(event) {
      if (event.environment === "local") return null;

      return event;
    },
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === "ui.click") {
        const { target } = hint.event;

        if (target.ariaLabel) {
          breadcrumb.message = target.ariaLabel;
        } else {
          breadcrumb.message += ` (${target.innerText})`;
        }
      } else if (
        breadcrumb.category === "fetch" &&
        breadcrumb?.data?.url?.match(/^data:/)
      ) {
        breadcrumb.data.url = "b64_file";
      }

      return breadcrumb;
    },
    ignoreErrors: ["Non-Error promise rejection captured"],
  });
}
