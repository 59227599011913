import { useRef } from "react";
import { downloadFile } from "@utils/utils";
import { formatDate, formatShortDate, formatWeekday } from "@utils/date.utils";
import { LessonType } from "@customTypes/LessonType";
import { Modal } from "@components/Modal";
import { Button } from "@components/Button";
import { CompletedHomeworkPill } from "@components/CompletedHomeworkPill";
import { MaterialPill } from "@components/MaterialPill";
import { PaymentStatus } from "@components/PaymentStatus";
import { ReactComponent as TrashImg } from "@img/trash_2.svg";
import { ReactComponent as RemoveImg } from "@img/remove.svg";
import { ReactComponent as AttachmentImg } from "@img/attachment.svg";
import cs from "./LessonsListPage.module.scss";

type DetailsModalProps = {
  isOpen: boolean;
  activeLesson: LessonType | null;
  onCancel: () => void;
  onUploadHomework: (file: File) => void;
  onDeleteHomework: (homeworkId: string) => void;
  onClose: () => void;
};

export const DetailsModal = ({
  isOpen,
  activeLesson,
  onCancel,
  onUploadHomework,
  onDeleteHomework,
  onClose,
}: DetailsModalProps) => {
  const fileInputElement = useRef<HTMLInputElement>(null);

  if (!activeLesson) return null;

  const weekday = formatWeekday(activeLesson.date, "short");
  const date = formatShortDate(activeLesson.date);
  const formattedDate = activeLesson.date
    ? `${weekday}, ${date}, ${activeLesson.startHourStr}-${activeLesson.endHourStr}`
    : "-";

  return (
    <Modal
      title="Szczegóły lekcji"
      isOpen={isOpen}
      className={cs.modal}
      footer={
        <Button color="primary" onClick={onClose}>
          Zamknij
        </Button>
      }
      onClose={onClose}
    >
      <div className={cs.detailsModal_actions}>
        <div className="v-center">
          {!activeLesson.isPast && (
            <Button color="danger" onClick={onCancel}>
              <TrashImg width={12} />

              <span>Odwołaj</span>
            </Button>
          )}
        </div>
      </div>

      <div className={cs.detailsModal_body} tabIndex={0}>
        <div className={cs.sidePanelSection__main}>
          <div className="medium">Temat:</div>
          <div className="medium">{activeLesson.topic}</div>

          <div className="medium">Tutor:</div>
          <div>{activeLesson.tutor.name}</div>

          <div className="medium">Data i godzina:</div>
          <div>{formattedDate}</div>
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">Odeślij zadania domowe:</div>
          <div>
            <span className={cs.materials}>
              {activeLesson.completedHomeworks.length > 0
                ? activeLesson.completedHomeworks.map((homework) => (
                    <div className={cs.homework}>
                      <CompletedHomeworkPill
                        key={homework._id}
                        homework={homework}
                      />

                      <button
                        type="button"
                        className="btn-icon"
                        onClick={() => onDeleteHomework(homework._id)}
                      >
                        <RemoveImg width={12} />
                      </button>
                    </div>
                  ))
                : "-"}
            </span>

            <Button
              color="primary"
              className={cs.uploadButton}
              onClick={() => {
                fileInputElement.current?.click();
              }}
            >
              <AttachmentImg width={14} className="mr8" />

              <span>Kliknij aby wybrać z dysku</span>

              <input
                type="file"
                multiple
                className={cs.fileInput}
                ref={fileInputElement}
                onChange={(e) => {
                  if (!e.target.files) return;

                  if (e.target.files?.length > 0) {
                    Array.from(e.target.files).forEach((file) => {
                      onUploadHomework(file);
                    });
                  }

                  e.target.value = "";
                }}
              />
            </Button>
          </div>
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">
            <div>Zad. domowe:</div>
            {activeLesson.homeworks.length > 0 && (
              <div>
                <button
                  className="link btn-icon"
                  type="button"
                  onClick={() => {
                    activeLesson?.homeworks.forEach((homework) => {
                      if (homework.type !== "link") {
                        downloadFile(homework.link);
                      }
                    });
                  }}
                >
                  Pobierz
                </button>
              </div>
            )}
          </div>
          <div>
            <span className={cs.materials}>
              {activeLesson.homeworks.length > 0
                ? activeLesson.homeworks.map((homework) => (
                    <MaterialPill
                      key={homework._id}
                      material={homework}
                      config={{ hideHomeworkInfo: true, color: "orange" }}
                    />
                  ))
                : "-"}
            </span>
          </div>

          <div className="medium">
            <div>Materiały:</div>
            {activeLesson.materials.length > 0 && (
              <div>
                <button
                  className="link btn-icon"
                  type="button"
                  onClick={() => {
                    activeLesson?.materials.forEach((material) => {
                      if (material.type !== "link") {
                        downloadFile(material.link);
                      }
                    });
                  }}
                >
                  Pobierz
                </button>
              </div>
            )}
          </div>
          <div>
            <span className={cs.materials}>
              {activeLesson.materials.length > 0
                ? activeLesson.materials.map((material) => (
                    <MaterialPill key={material._id} material={material} />
                  ))
                : "-"}
            </span>
          </div>

          <div className="medium">Opis lekcji:</div>
          <div dangerouslySetInnerHTML={{ __html: activeLesson.desc || "-" }} />
        </div>

        <div className={cs.sidePanelSection__main}>
          <div className="medium">Cena: </div>
          <div>
            {activeLesson.price || "-"} {" zł"}
          </div>

          <div className="medium">Termin płatności:</div>
          <div>
            {activeLesson.paymentDeadline
              ? formatDate(activeLesson.paymentDeadline)
              : "-"}
          </div>

          {activeLesson.arrears?.length > 0 && (
            <>
              <div className="medium">Status płatności:</div>
              <div>
                <PaymentStatus arrears={activeLesson.arrears} />
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};
