import { CalendarEventsDtoService } from "@services/CalendarEventsDtoService";
import { DateType } from "@customTypes/DateType";
import { CalendarEventsDto } from "@customTypes/CalendarEventsDto";
import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/calendar");

export class CalendarApi {
  static async fetchAll(startDate: DateType, endDate: DateType) {
    const { data } = await api.get<CalendarEventsDto>("", {
      params: {
        startDate,
        endDate,
      },
    });

    return CalendarEventsDtoService.transform(data);
  }
}
