import { useLocation } from "react-router-dom";
import { Account } from "./Account";
import { MobileMenu } from "./MobileMenu";
import cs from "./AppBar.module.scss";

export const AppBar = () => {
  let location = useLocation();

  return (
    <header className={cs.root}>
      <div className={cs.pageName}>{getPageName(location.pathname)}</div>

      <div className={cs.actions}>
        <Account />

        <MobileMenu />
      </div>
    </header>
  );

  function getPageName(urlPath: string) {
    // TODO:
    switch (urlPath) {
      case "/":
        return "Kalendarz";
      case "/tutors":
        return "Lista tutorów";
      case "/lessons":
        return "Lista lekcji";
      case "/finances":
        return "Finanse";
      case "/settings":
        return "Ustawienia";
      default:
        return "Test";
    }
  }
};
