import { Dropdown } from "../../components/Dropdown";
import { ViewType } from "./CalendarPage";
import { ReactComponent as DropdownArrowImg } from "../../img/dropdown_arrow.svg";
import cs from "./ViewPicker.module.scss";

type OptionType = {
  value: ViewType;
  label: string;
};

const options: OptionType[] = [
  {
    value: "workweek",
    label: "Tydzień roboczy",
  },
  {
    value: "week",
    label: "Tydzień",
  },
  {
    value: "month",
    label: "Miesiąc",
  },
];

type ViewPickerProps = {
  selected: ViewType;
  onChange: (selected: ViewType) => void;
};

export const ViewPicker = ({ selected, onChange }: ViewPickerProps) => {
  const selectedOption = options.find((option) => option.value === selected);

  return (
    <Dropdown
      options={options.map((option) => (
        <div
          onClick={() => {
            onChange(option.value);
          }}
        >
          {option.label}
        </div>
      ))}
    >
      <span className={cs.root}>
        <span>{selectedOption?.label}</span>

        <DropdownArrowImg width={10} />
      </span>
    </Dropdown>
  );
};
