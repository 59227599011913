import { useEffect, useState } from "react";
import { useAddTimeSlotApplication } from "@hooks/useAddTimeSlotApplication";
import { diff } from "@utils/date.utils";
import { TimeSlotType } from "@customTypes/TimeSlotType";
import { Modal } from "@components/Modal";
import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { Input } from "@components/Input";
import cs from "./TimeSlotApplicationModal.module.scss";

type TimeSlotApplicationModalProps = {
  timeSlot: TimeSlotType;
  isOpen: boolean;
  onClose: () => void;
};

export const TimeSlotApplicationModal = ({
  timeSlot,
  isOpen,
  onClose,
}: TimeSlotApplicationModalProps) => {
  const [startDate, setStartDate] = useState<Date>();
  const [duration, setDuration] = useState<string>("");

  const addTimeSlotApplication = useAddTimeSlotApplication();

  useEffect(() => {
    if (isOpen && timeSlot) {
      setStartDate(timeSlot.date);
      setDuration("60");
    }
  }, [timeSlot, isOpen]);

  const footer = (
    <div>
      <Button
        color="primary"
        isLoading={addTimeSlotApplication.isLoading}
        onClick={handleDelete}
      >
        Aplikuj
      </Button>
    </div>
  );

  return (
    <Modal
      title="Aplikuj"
      isOpen={isOpen}
      footer={footer}
      className={cs.modal}
      onClose={onClose}
    >
      <DatePicker
        label="Data i godzina rozpoczęcia"
        mode="datetime"
        value={startDate}
        required
        minDate={timeSlot.date}
        maxDate={timeSlot.date}
        minHour={timeSlot.date}
        maxHour={timeSlot.endDate}
        onChange={handleStartDateSelected}
      />

      <Input
        type="number"
        value={duration}
        label="Czas trwania"
        required
        onChange={handleDurationChange}
      />
    </Modal>
  );

  async function handleDelete() {
    if (startDate && duration) {
      await addTimeSlotApplication.mutateAsync({
        timeSlotId: timeSlot._id,
        application: {
          startDate,
          duration: Number(duration),
        },
      });

      onClose();
    }
  }

  function handleStartDateSelected(selectedDate: Date) {
    setStartDate(selectedDate);

    let duration = diff(timeSlot.endDate, selectedDate, "minutes");

    if (duration > 60) duration = 60;

    setDuration(String(duration));
  }

  function handleDurationChange(duration: string) {
    if (startDate) {
      let maxDuration = diff(timeSlot.endDate, startDate, "minutes");

      if (+duration > maxDuration) {
        setDuration(String(maxDuration));
      } else {
        setDuration(duration);
      }
    }
  }
};
