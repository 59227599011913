//@ts-nocheck
import { getDatesInRange, isCurrentHour } from "@utils/date.utils";
import { WeekViewDay } from "./WeekViewDay";
import cs from "./WeekView.module.scss";

const startHour = 6;
const endHour = 22;

const calendarHours = new Array(endHour - startHour + 1)
  .fill(1)
  .map((el, index) => ({ hour: index + startHour }));

export const WeekView = ({ startDate, endDate, events }) => {
  const datesRange = getDatesInRange(startDate, endDate);

  return (
    <div className={cs.WeekView}>
      <div>
        {calendarHours.map((calendarHour: any) => {
          let className = cs.hour;

          if (isCurrentHour(calendarHour.hour)) {
            className += " " + cs.hour__current;
          }

          return (
            <div key={calendarHour.hour} className={cs.day}>
              <div className={cs.day__hour}>
                <span className={className}>{calendarHour.hour}</span>
              </div>
            </div>
          );
        })}
      </div>

      <div className={cs.days} data-cols={datesRange.length}>
        {datesRange.map((date) => (
          <WeekViewDay key={date} date={date} events={events} />
        ))}
      </div>
    </div>
  );
};
