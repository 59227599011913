import { useEffect, useState } from "react";
import { formatDate } from "@utils/date.utils";
import { CalendarEvent } from "@customTypes/CalendarEvent";
import { Event } from "@components/CalendarSingleDay/Event";
import cs from "./WeekView.module.scss";

const startHour = 6;
const endHour = 22;

function getFreshCalendarHours(date: Date) {
  return new Array(endHour - startHour + 1).fill(1).map((el, index) => {
    const hour = index + startHour;
    const dateCopy = new Date(date);

    return {
      hour,
      date: new Date(dateCopy.setHours(hour, 0, 0, 0)),
    };
  });
}

export const WeekViewDay = ({ date, events }: { date: Date; events: any }) => {
  const [calendarHours, setCalendarHours] = useState(() => {
    return getFreshCalendarHours(date);
  });

  useEffect(() => {
    const selectedDayAsIso = formatDate(date, "iso");

    const selectedDayEvents = events[selectedDayAsIso];

    if (!selectedDayEvents?.length) return;

    // TODO: Use immer
    const calendarHoursCopy = getFreshCalendarHours(date);

    selectedDayEvents.forEach((event: any) => {
      const relatedHour = calendarHoursCopy.find(
        (calendarHour) => event.startHour === calendarHour.hour,
      ) as any;

      if (!relatedHour) return;

      // TODO:
      relatedHour.data = [...(relatedHour.data || []), event];
    });

    setCalendarHours(calendarHoursCopy);
  }, [date, events]);

  return (
    <div className={cs.root}>
      <div>
        {calendarHours.map((calendarHour: any, index) => (
          <div
            key={calendarHour.hour}
            className={cs.day}
            style={{ zIndex: 30 - index }}
          >
            <div className={cs.day__content}>
              {calendarHour.data?.map((event: CalendarEvent) => (
                <Event key={event.details._id} event={event} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
