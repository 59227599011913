import { createAxiosInstance } from "./_createAxiosInstance";
import { LessonDtoService } from "@services/LessonDtoService";

const api = createAxiosInstance("/api/shared-lesson");

type UploadHomeworkParams = {
  lessonId: string;
  file: File;
};

export class SharedLessonApi {
  static async fetchById(lessonId: string) {
    const { data } = await api.get(`/${lessonId}`);

    return LessonDtoService.transform(data);
  }

  static async cancelById({ lessonId }: { lessonId: string }) {
    return api.delete(`/${lessonId}`);
  }

  static async uploadHomework({ lessonId, file }: UploadHomeworkParams) {
    const form = new FormData();

    form.append("file", file);

    return api.post(`/${lessonId}/completed-homework`, form);
  }

  static async deleteHomeworkById({
    lessonId,
    homeworkId,
  }: {
    lessonId: string;
    homeworkId: string;
  }) {
    return api.delete(`/${lessonId}/completed-homework/${homeworkId}`);
  }

  static async markAsPaid({ lessonId }: { lessonId: string }) {
    return api.post(`/${lessonId}/arrear`);
  }
}
