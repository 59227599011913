import React from "react";
import classNames from "classnames";
import { MenuItem } from "./MenuItem";
import logoImg from "./img/logo.svg";
import calendarImg from "./img/calendar.svg";
import studentsCenterImg from "./img/students_center.svg";
import studyPlanerImg from "./img/study_planer.svg";
import financesImg from "./img/finances.svg";
import settingsImg from "./img/settings.svg";
import chatImg from "./img/chat.svg";
import faqImg from "./img/faq.svg";
import cs from "./Menu.module.scss";

export const Menu = ({ className }: { className?: string }) => {
  const rootsCs = classNames("Menu", cs.root, className);

  return (
    <aside className={rootsCs}>
      <div className={cs.top}>
        <img src={logoImg} alt="" />

        <span>up4edu</span>
      </div>

      <nav className={cs.navigation}>
        <MenuItem text="Kalendarz" link="/" icon={calendarImg} />
        <MenuItem text="Spotkania" link="/lessons" icon={studyPlanerImg} />
        <MenuItem text="Finanse" link="/finances" icon={financesImg} />
        <MenuItem text="Tutorzy" link="/tutors" icon={studentsCenterImg} />
      </nav>

      <nav className={cs.bottom}>
        <MenuItem text="Ustawienia" link="/settings" icon={settingsImg} />
        {/* TODO: Later */}
        {/*<MenuItem text="Masz pytanie?" link="/question" icon={chatImg} />*/}
        {/*<MenuItem text="FAQ" link="/faq" icon={faqImg} />*/}
      </nav>
    </aside>
  );
};
