import React, { useEffect, useRef, useState } from "react";
import { useCurrentUser } from "@hooks/useCurrentUser";
import { useReplaceCurrentUser } from "@hooks/useReplaceCurrentUser";
import { useUpdatePassword } from "@hooks/useUpdatePassword";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { TabKeyType, Tabs } from "./Tabs";
import cs from "./Settings.module.scss";

export const Settings = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [activeTab, setActiveTab] = useState<TabKeyType>("general");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const currentPasswordElement = useRef<HTMLInputElement>(null);
  const confirmPasswordElement = useRef<HTMLInputElement>(null);

  const { currentUser } = useCurrentUser();
  const replaceCurrentUser = useReplaceCurrentUser();
  const updatePassword = useUpdatePassword();

  useEffect(() => {
    if (currentUser) {
      setFirstName(currentUser.firstName);
      setLastName(currentUser.lastName);
      setPhone(currentUser.phone || "");
    }
  }, [currentUser]);

  return (
    <main className={cs.root}>
      <Tabs activeTab={activeTab} onChange={setActiveTab} />

      {activeTab === "general" && (
        <section className={cs.box}>
          <Input
            value={currentUser?.email || ""}
            label="Adres e-mail"
            type="email"
            disabled
          />

          <form onSubmit={handleSubmit}>
            <Input
              value={firstName}
              label="Imię"
              required
              onChange={setFirstName}
            />
            <Input
              value={lastName}
              label="Nazwisko"
              required
              onChange={setLastName}
            />
            <Input
              value={phone}
              label="Numer telefonu"
              type="tel"
              pattern="(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)"
              onChange={setPhone}
            />

            <Input value="Polski" label="Język" disabled />
            <Input value="PLN" label="Waluta" disabled />

            <div className="text-center mt32">
              <Button
                color="secondary"
                className={cs.submitBtn}
                htmlType="submit"
              >
                Aktualizuj dane
              </Button>
            </div>
          </form>
        </section>
      )}

      {activeTab === "security" && (
        <section className={cs.box}>
          <form onSubmit={handleChangePassword}>
            <Input
              value={currentPassword}
              label="Aktualne hasło"
              required
              type="password"
              ref={currentPasswordElement}
              onChange={(val) => {
                setCurrentPassword(val);
                currentPasswordElement.current?.setCustomValidity("");
              }}
            />

            <Input
              value={newPassword}
              label="Nowe hasło"
              required
              type="password"
              onChange={setNewPassword}
            />

            <Input
              value={confirmPassword}
              label="Potwierdź nowe hasło"
              type="password"
              ref={confirmPasswordElement}
              onChange={(val) => {
                setConfirmPassword(val);
                confirmPasswordElement.current?.setCustomValidity("");
              }}
            />

            <div className="f-center mt32">
              <Button
                color="secondary"
                className={cs.submitBtn}
                htmlType="submit"
                isLoading={updatePassword.isLoading}
              >
                Aktualizuj hasło
              </Button>
            </div>
          </form>
        </section>
      )}
    </main>
  );

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    replaceCurrentUser.mutate({
      currentUser: {
        ...currentUser,
        firstName,
        lastName,
        phone,
      },
    });
  }

  async function handleChangePassword(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    try {
      await updatePassword.mutateAsync({
        newPassword,
        currentPassword,
      });

      setConfirmPassword("");
      setNewPassword("");
      setCurrentPassword("");
    } catch (error: any) {
      if (error.message === "CURRENT_PASSWORD_INCORRECT") {
        currentPasswordElement.current?.setCustomValidity(
          "Podane hasło nie jest prawidłowe",
        );
        currentPasswordElement.current?.reportValidity();
      }
    }
  }

  function validatePassword() {
    if (newPassword !== confirmPassword) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );
      confirmPasswordElement.current?.reportValidity();

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }
};
