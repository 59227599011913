import { useRef } from "react";
import classNames from "classnames";
import { downloadFile, openLink } from "@utils/utils";
import { AssignedMaterialType } from "@customTypes/AssignedMaterialType";
import houseIcon from "@img/house_icon.svg";
import { ReactComponent as PdfIcon } from "@img/pdf_icon.svg";
import { ReactComponent as LinkIcon } from "@img/link_icon.svg";
import { ReactComponent as ImageIcon } from "@img/image.svg";
import { ReactComponent as DocsImg } from "@img/doc.svg";
import { ReactComponent as FileImg } from "@img/file.svg";
import cs from "./MaterialPill.module.scss";

const MaterialIcon = ({ material }: { material: AssignedMaterialType }) => {
  switch (material.type) {
    case "image":
      return <ImageIcon width={14} />;
    case "link":
      return <LinkIcon width={14} />;
    case "pdf":
      return <PdfIcon width={14} />;
    case "doc":
      return <DocsImg width={14} />;
    case "other":
      return <FileImg width={12} />;
    default:
      throw new Error(
        "Unknown material type: " +
          material.type +
          "; " +
          material.name +
          "; " +
          material._id,
      );
  }
};

type MaterialPillProps = {
  material: AssignedMaterialType;
  config?: {
    hideHomeworkInfo: boolean;
    color?: "orange";
  };
};

export const MaterialPill = ({ material, config }: MaterialPillProps) => {
  const csRoot = classNames(cs.root, {
    [cs.orange]: config?.color === "orange",
  });

  const rootElement = useRef<any>();

  return (
    <div
      className={csRoot}
      title={material.name}
      ref={rootElement}
      onClick={() => {
        const parentElement = rootElement.current.parentElement;

        if (material.type !== "link") {
          downloadFile(material.link, parentElement);
        } else {
          openLink(material.link, parentElement);
        }
      }}
    >
      <div className={cs.typeIconContainer}>
        <MaterialIcon material={material} />
      </div>

      <span className={cs.name}>{material.name}</span>

      {material.isHomework && !config?.hideHomeworkInfo && (
        <img className={cs.homeworkIcon} src={houseIcon} alt="" />
      )}
    </div>
  );
};
