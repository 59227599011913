import { addMinutes, formatHour } from "@utils/date.utils";
import { ArrearDtoService } from "@services/ArrearDtoService";
import { TutorDtoService } from "@services/TutorDtoService";
import { LessonDtoType } from "@customTypes/LessonDtoType";
import { LessonType } from "@customTypes/LessonType";

const NOW = new Date();

export class LessonDtoService {
  static transformAll(lessonDtos: LessonDtoType[]) {
    return lessonDtos.map(LessonDtoService.transform);
  }

  static transform(lessonDto: LessonDtoType): LessonType {
    lessonDto = LessonDtoService.formatDates(lessonDto);

    const endDate = addMinutes(lessonDto.date, lessonDto.duration);

    const arrears =
      lessonDto.arrears?.map((arrearDto) =>
        ArrearDtoService.transform(arrearDto, lessonDto),
      ) || [];

    return {
      ...lessonDto,
      endDate,
      isPast: endDate < NOW,
      startHourStr: formatHour(lessonDto.date),
      endHourStr: formatHour(endDate),
      tutor: TutorDtoService.transform(lessonDto.tutor),
      materials: lessonDto.materials.filter((material) => !material.isHomework),
      homeworks: lessonDto.materials.filter((material) => material.isHomework),
      arrears,
    };
  }

  private static formatDates(lessonDto: LessonDtoType): LessonDtoType {
    const copy = { ...lessonDto };

    if (lessonDto.date) {
      copy.date = new Date(lessonDto.date);
    }
    if (copy.paymentDeadline) {
      copy.paymentDeadline = new Date(lessonDto.paymentDeadline);
    }

    return copy;
  }
}
