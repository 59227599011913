import { TutorDtoType } from "@customTypes/TutorDtoType";
import { TutorType } from "@customTypes/TutorType";
export class TutorDtoService {
  static transformAll(studentDtos: TutorDtoType[]): TutorType[] {
    return studentDtos.map((dto) => TutorDtoService.transform(dto));
  }

  static transform(tutorDto: TutorDtoType): TutorType {
    return {
      ...tutorDto,
      shortName: tutorDto.firstName[0] + ". " + tutorDto.lastName,
    };
  }
}
