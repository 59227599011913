import { useState } from "react";
import { TimeSlotType } from "@customTypes/TimeSlotType";
import { TimeSlotApplicationModal } from "@components/TimeSlotApplicationModal";
import {
  calcBoxHeight,
  calcStartPosition,
} from "@components/CalendarLessonEvent/CalendarLessonEvent";
import cs from "./CalendarTimeSlotEvent.module.scss";

type CalendarTimeSlotEventProps = {
  timeSlot: TimeSlotType;
};

export const CalendarTimeSlotEvent = ({
  timeSlot,
}: CalendarTimeSlotEventProps) => {
  const [areDetailsOpened, setAreDetailsOpened] = useState(false);

  const height = calcBoxHeight(timeSlot.duration);
  const top = calcStartPosition(timeSlot.date);

  return (
    <>
      <div
        className={cs.root}
        style={{ height, top }}
        onClick={() => setAreDetailsOpened(true)}
      >
        <div>
          <span className={cs.title}>DOSTĘPNOŚĆ</span>
          <span className={cs.hours}>
            {timeSlot.startHourStr}-{timeSlot.endHourStr}
          </span>
        </div>

        <div>{timeSlot.tutor.name}</div>
      </div>

      <TimeSlotApplicationModal
        timeSlot={timeSlot}
        isOpen={areDetailsOpened}
        onClose={() => setAreDetailsOpened(false)}
      />
    </>
  );
};
