import { useMutation, useQueryClient } from "react-query";
import { FinancesApi } from "@api/FinancesApi";
import { replaceWithId } from "@utils/utils";
import { StandaloneArrearType } from "@customTypes/StandaloneArrearType";

export const useChangeArrearStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(FinancesApi.changePaymentStatus, {
    onMutate: async ({ arrearId, status }) => {
      await queryClient.cancelQueries("standalone-arrears");

      const currentCache =
        queryClient.getQueriesData<StandaloneArrearType[]>(
          "standalone-arrears",
        );

      if (currentCache) {
        if (currentCache) {
          currentCache.forEach(([queryKey, queryValue]) => {
            let itemToUpdate = queryValue.find(({ _id }) => _id === arrearId);
            if (itemToUpdate) {
              const updatedItem = {
                ...itemToUpdate,
                updatedAt: new Date(),
                status,
              };

              queryClient.setQueriesData(
                queryKey,
                replaceWithId(queryValue, updatedItem),
              );
            }
          });
        }
      }

      return { currentCache };
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueriesData("standalone-arrears", context?.currentCache);
    },
  });
};
