import { useMutation, useQueryClient } from "react-query";
import { TimeSlotApi } from "@api/TimeSlotApi";

export const useAddTimeSlotApplication = () => {
  const queryClient = useQueryClient();

  return useMutation(TimeSlotApi.createApplication, {
    onSuccess() {
      return queryClient.invalidateQueries({
        queryKey: ["calendar"],
      });
    },
  });
};
