import * as Sentry from "@sentry/react";
import { createAxiosInstance } from "./_createAxiosInstance";
import { TutorDtoService } from "@services/TutorDtoService";
import { TutorDtoType } from "@customTypes/TutorDtoType";

const api = createAxiosInstance("/api/tutor");
const invitationApi = createAxiosInstance("/api/invitation");

type DeleteByIdParams = {
  tutorId: string;
};

export class TutorApi {
  static async fetchAll() {
    const { data } = await api.get<TutorDtoType[]>("");

    return TutorDtoService.transformAll(data);
  }

  static async deleteById({ tutorId }: DeleteByIdParams) {
    return api.delete(`/${tutorId}`);
  }

  static async acceptInvitation(invitationId: string) {
    try {
      await api.post(`/accept-invitation/${invitationId}`);

      return { isSuccess: true };
    } catch (error) {
      console.error(error);

      Sentry.captureException(error);

      return { isSuccess: false };
    }
  }

  static async fetchInvitationDetails(invitationId: string) {
    const { data } = await invitationApi.get<{
      invitationId: string;
      studentAccountEmail?: string;
      tutorName: string;
    }>(`/${invitationId}`);

    return data;
  }
}
