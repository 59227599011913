// @ts-nocheck
import { RadioButton } from "@components/RadioButton";
import cs from "./FinancesListPage.module.scss";

// TODO: Wyczyść filtry

export const Filters = ({ filters, onChange }) => {
  return (
    <section className={cs.filters}>
      <div className="mb24">
        <div className={cs.filters_title}>Status</div>
        <div className={cs.filters_status}>
          <RadioButton
            checked={filters.status === "all"}
            label="Wszystkie"
            name="finances_status"
            value="all"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "after_deadline"}
            label="Po terminie płatności"
            name="finances_status"
            value="after_deadline"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "all_awaiting"}
            label="Wszystkie nieopłacone"
            name="finances_status"
            value="all_awaiting"
            onChange={handleStatusFilterChanged}
          />
          <RadioButton
            checked={filters.status === "paid"}
            label="Opłacone"
            name="finances_status"
            value="paid"
            onChange={handleStatusFilterChanged}
          />
        </div>
      </div>
    </section>
  );

  function handleStatusFilterChanged(value: string) {
    onChange("status", value);
  }
};
