import { useQuery } from "react-query";
import { TutorApi } from "@api/TutorApi";

export const useInvitation = (invitationId: string) => {
  return useQuery(
    ["invitation", invitationId],
    () => TutorApi.fetchInvitationDetails(invitationId),
    {
      enabled: Boolean(invitationId),
    },
  );
};
