import cs from "./Settings.module.scss";

export type TabKeyType = "general" | "subscription" | "security";

type TabsProps = {
  activeTab: TabKeyType;
  onChange: (tab: TabKeyType) => void;
};

export const Tabs = ({ activeTab, onChange }: TabsProps) => {
  return (
    <header className={cs.tabs}>
      <div className={getCs("general")} onClick={() => onChange("general")}>
        Ustawienia
      </div>
      <div className={getCs("security")} onClick={() => onChange("security")}>
        Bezpieczeństwo
      </div>
    </header>
  );

  function getCs(tabKey: TabKeyType) {
    if (activeTab === tabKey) return cs.tab__active;

    return cs.tab;
  }
};
