import * as Sentry from "@sentry/react";
import { useQuery } from "react-query";
import { AuthApi } from "@api/AuthApi";

export const useCurrentUser = () => {
  const { data, isLoading } = useQuery("user", AuthApi.fetchCurrentUser, {
    staleTime: Infinity,
    cacheTime: Infinity,
    onSuccess(data) {
      Sentry.setUser({ id: data._id });
    },
  });

  return {
    currentUser: data,
    isLoading,
  };
};
