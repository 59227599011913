import { CompletedHomeworkType } from "../../types/CompletedHomeworkType";
import cs from "./CompletedHomeworkPill.module.scss";

type CompletedHomeworkPillProps = {
  homework: CompletedHomeworkType;
};

export const CompletedHomeworkPill = ({
  homework,
}: CompletedHomeworkPillProps) => {
  return (
    <div className={`CompletedHomeworkPill ${cs.root}`} title={homework.name}>
      <span className={cs.name}>{homework.name}</span>
    </div>
  );
};
