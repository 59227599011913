import { TimeSlotDtoType } from "@customTypes/TimeSlotDtoType";
import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/time-slot");

type CreateApplicationParams = {
  timeSlotId: string;
  application: {
    startDate: Date;
    duration: number;
  };
};

export class TimeSlotApi {
  static async createApplication({
    timeSlotId,
    application,
  }: CreateApplicationParams) {
    return api.post<TimeSlotDtoType>(`/${timeSlotId}/application`, application);
  }
}
