import { TutorDtoService } from "@services/TutorDtoService";
import { addMinutes, formatHour } from "@utils/date.utils";
import { TimeSlotDtoType } from "@customTypes/TimeSlotDtoType";
import { TimeSlotType } from "@customTypes/TimeSlotType";

export class TimeSlotDtoService {
  static transform(timeSlot: TimeSlotDtoType): TimeSlotType {
    const endDate = addMinutes(timeSlot.date, timeSlot.duration);

    return {
      ...timeSlot,
      date: new Date(timeSlot.date),
      endDate,
      startHourStr: formatHour(timeSlot.date),
      endHourStr: formatHour(endDate),
      tutor: TutorDtoService.transform(timeSlot.tutor),
    };
  }
}
