import { useRef, ReactNode } from "react";
import { useAppStore } from "@store";
import { useDeleteLesson } from "@hooks/useDeleteLesson";
import { useUploadHomework } from "@hooks/useUploadHomework";
import { useDeleteHomework } from "@hooks/useDeleteHomework";
import { Popover, PopoverPosition } from "@components/Popover";
import { LessonDetailsPopoverContent } from "./LessonDetailsPopoverContent";

type LessonDetailsPopoverProps = {
  lesson: any;
  popoverPosition?: PopoverPosition;
  children: ReactNode;
};

export const LessonDetailsPopover = ({
  lesson,
  popoverPosition,
  children,
}: LessonDetailsPopoverProps) => {
  const popover = useRef<any>(null);

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  const deleteLesson = useDeleteLesson();
  const uploadHomework = useUploadHomework();
  const deleteHomework = useDeleteHomework();

  return (
    <Popover
      popover={
        <LessonDetailsPopoverContent
          lesson={lesson}
          onDelete={handleDelete}
          onUploadHomework={async (file) => {
            await uploadHomework.mutateAsync({
              lessonId: lesson._id,
              file,
            });
          }}
          onDeleteHomework={async (homeworkId) => {
            await deleteHomework.mutateAsync({
              lessonId: lesson._id,
              homeworkId,
            });
          }}
        />
      }
      position={popoverPosition}
      ref={popover}
    >
      {children}
    </Popover>
  );

  async function handleDelete() {
    popover.current?.close();

    const verb = lesson?.isPast ? "usunąć" : "odwołać";
    const isConfirmed = await openConfirmationModal({
      confirmText: "Usuń",
      text: `Czy na pewno chcesz ${verb} tę lekcję?`,
    });

    if (isConfirmed && lesson) {
      await deleteLesson.mutate({ lessonId: lesson._id });
    }
  }
};
