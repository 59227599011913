import { LessonType } from "../../types/LessonType";
import { LessonDetailsPopover } from "../LessonDetailsPopover";
import cs from "./CalendarLessonEvent.module.scss";

type CalendarLessonEventProps = {
  lesson: LessonType;
};

export const CalendarLessonEvent = ({ lesson }: CalendarLessonEventProps) => {
  const height = calcBoxHeight(lesson.duration);
  const top = calcStartPosition(lesson.date);

  return (
    <LessonDetailsPopover
      lesson={lesson}
      popoverPosition={["left", "right", "bottom", "top"]}
    >
      <div className={cs.root} style={{ height, top }}>
        <div>
          <span className={cs.participants}>{lesson.tutor.shortName}</span>

          <span className={cs.hours}>
            {lesson.startHourStr}-{lesson.endHourStr}
          </span>
        </div>

        <div className={cs.topic} title={lesson.topic}>
          {lesson.topic}
        </div>
      </div>
    </LessonDetailsPopover>
  );
};

export function calcBoxHeight(duration: number) {
  // NOTE: STEP 1: 1 min === 1 px
  const height = duration;

  // NOTE: STEP 2: Add borders width
  const howManyBorders = Math.floor(height / 60);

  return height + howManyBorders;
}

export function calcStartPosition(date: Date) {
  return date.getMinutes();
}
