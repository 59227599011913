//@ts-nocheck
import React, {
  forwardRef,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { Popover as PopoverBase } from "react-tiny-popover";
import cs from "./Popover.module.scss";

export type PopoverPosition = Array<"top" | "bottom" | "left" | "right">;

type PopoverProps = {
  children: React.ReactNode;
  popover: React.ReactNode;
  position?: PopoverPosition;
  align?: PopoverAlign;
  square?: boolean;
  adjustWidth?: boolean;
  className?: string;
};

export const Popover = forwardRef(
  (
    {
      children,
      popover,
      position = ["top", "bottom", "left", "right"],
      align = "start",
      className,
      square,
      adjustWidth,
    }: PopoverProps,
    ref,
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const [width, setWidth] = useState("");

    const childRef = useRef(null);

    let child = React.Children.only(children);

    useLayoutEffect(() => {
      if (adjustWidth && childRef.current) {
        setWidth(`${childRef.current.clientWidth - 4}px`);
      }
    }, [child]);

    child = React.cloneElement(child, {
      onClick: () => setIsPopoverOpen(!isPopoverOpen),
    });

    useImperativeHandle(ref, () => ({
      close: () => {
        setIsPopoverOpen(false);
      },
    }));

    const rootCs = classNames(cs.container, className, {
      [cs.square]: square,
    });

    return (
      <PopoverBase
        isOpen={isPopoverOpen}
        positions={position} // preferred positions by priority
        content={popover}
        containerStyle={{ minWidth: width }}
        containerClassName={rootCs}
        align={align}
        ref={childRef}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        {child}
      </PopoverBase>
    );
  },
);
