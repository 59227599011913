import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { ArrearDtoType } from "@customTypes/ArrearDtoType";
import { ArrearType } from "@customTypes/ArrearType";
import { LessonDtoType } from "@customTypes/LessonDtoType";

const NOW = new Date();

export class ArrearDtoService {
  static transform(
    arrearDto: ArrearDtoType,
    relatedLesson: LessonDtoType,
  ): ArrearType {
    return {
      ...arrearDto,
      isAfterDeadline:
        arrearDto.status === LessonPaymentStatus.AWAITS &&
        relatedLesson.paymentDeadline &&
        relatedLesson.paymentDeadline < NOW,
    };
  }
}
