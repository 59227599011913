import React, { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { ToastService } from "@services/ToastService";
import { useResetPassword } from "@hooks/useResetPassword";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Page } from "../components/Page";
import cs from "./NewPasswordPage.module.scss";

export const NewPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const confirmPasswordElement = useRef<any>(null);

  const resetPassword = useResetPassword();

  const params = useParams();

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Ustaw nowe hasło</div>
        </header>

        <form onSubmit={handleSubmit}>
          <Input
            value={password}
            label="Hasło"
            type="password"
            required
            autocomplete="new-password"
            name="password"
            onChange={(val) => {
              setPassword(val);
              confirmPasswordElement.current.setCustomValidity("");
            }}
          />

          <Input
            value={passwordConfirm}
            label="Potwierdź hasło"
            type="password"
            required
            ref={confirmPasswordElement}
            autocomplete="new-password"
            name="confirm-password"
            onChange={(val) => {
              setPasswordConfirm(val);
              confirmPasswordElement.current.setCustomValidity("");
            }}
          />

          <div className="f-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
              isLoading={resetPassword.isLoading}
            >
              Zapisz
            </Button>
          </div>
        </form>

        <div className={cs.noAccountText}>
          <Link to="/login" className="link">
            Wróć do strony logowania
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    try {
      await resetPassword.mutateAsync({
        password,
        token: params.token || "",
      });

      setPassword("");
      setPasswordConfirm("");

      ToastService.success("Hasło zostało zmienione.");

      setTimeout(() => {
        window.location.href = "/login";
      }, 1500);
    } catch (error: any) {
      if (error.message === "TOKEN_EXPIRED") {
        ToastService.error(
          "Link wygasł. Prosimy ponownie wygenerować wiadomość z linkiem resetującym.",
          { duration: 5000 },
        );
      } else {
        ToastService.generalError();
      }
    }
  }

  function validatePassword() {
    if (password !== passwordConfirm) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }
};
