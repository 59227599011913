import { Modal } from "../../components/Modal";
import { CalendarSingleDay } from "../../components/CalendarSingleDay";
import cs from "./SingleDayModal.module.scss";

type SingleDayModalProps = {
  isOpen: boolean;
  selectedDay: Date | null;
  onSelectedDayChange: (selectedDay: Date) => void;
  onClose: () => void;
};

export const SingleDayModal = ({
  isOpen,
  selectedDay,
  onSelectedDayChange,
  onClose,
}: SingleDayModalProps) => (
  <Modal isOpen={isOpen} className={cs.modal} onClose={onClose}>
    <CalendarSingleDay
      selectedDay={selectedDay}
      onSelectedDayChange={onSelectedDayChange}
    />
  </Modal>
);
