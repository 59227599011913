import { LessonDtoService } from "@services/LessonDtoService";
import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/lesson");

type UploadHomeworkParams = {
  lessonId: string;
  file: File;
};

export class LessonApi {
  static async fetchAll(filters: any, sort: any) {
    const { data } = await api.get("", {
      params: { sort },
    });

    return LessonDtoService.transformAll(data);
  }

  static async deleteById({ lessonId }: { lessonId: string }) {
    return api.delete(`/${lessonId}`);
  }

  static async uploadHomework({ lessonId, file }: UploadHomeworkParams) {
    const form = new FormData();

    form.append("file", file);

    return api.post(`/${lessonId}/completed-homework`, form);
  }

  static async deleteHomeworkById({
    lessonId,
    homeworkId,
  }: {
    lessonId: string;
    homeworkId: string;
  }) {
    return api.delete(`/${lessonId}/completed-homework/${homeworkId}`);
  }
}
