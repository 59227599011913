import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AuthApi } from "@api/AuthApi";
import { TutorApi } from "@api/TutorApi";
import { ToastService } from "@services/ToastService";
import { useInvitation } from "@hooks/useInvitation";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Spinner } from "@components/Spinner";
import { Page } from "../components/Page";
import cs from "./InvitationPage.module.scss";

const ExistingAccount = ({
  invitationId,
  studentAccountEmail,
}: {
  invitationId: string;
  studentAccountEmail: string;
}) => (
  <Page>
    <Page.Box>
      <div
        style={{
          paddingLeft: "30px",
          paddingRight: "30px",
          textAlign: "center",
          lineHeight: "1.4",
        }}
      >
        <header>
          <div className={cs.title}>Dzień dobry!</div>
          To zaproszenie jest już powiązane z istniejącym użytkownikiem:{" "}
          <div>
            <strong className="semi-bold">{studentAccountEmail}</strong>
          </div>
        </header>

        <div style={{ padding: 30 }}>
          <div className={cs.existingAccountText}>
            <span>To Twoje konto? </span>

            <Link to={`/login?invitation=${invitationId}`} className="link">
              Zaloguj się
            </Link>
          </div>
          <div className={cs.existingAccountText}>
            <span>Nie pamiętasz hasła? </span>

            <Link to="/remind-password" className="link">
              Zresetuj hasło
            </Link>
          </div>

          <div style={{ paddingTop: 25 }}>
            Jeśli to nie Twoje konto to powiadom o tym korepetytora.
          </div>
        </div>
      </div>
    </Page.Box>
  </Page>
);

export const InvitationPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCofirm, setPasswordCofirm] = useState("");

  const confirmPasswordElement = useRef<HTMLInputElement>(null);

  const params = useParams();
  const { data: invitation, isLoading } = useInvitation(
    params.invitationId as string,
  );

  useEffect(() => {
    if (!invitation || invitation.studentAccountEmail) return;

    (async () => {
      const currentUser = await AuthApi.fetchCurrentUser();

      if (currentUser) {
        await TutorApi.acceptInvitation(invitation.invitationId);
        ToastService.success("Zaproszenie zaakceptowane.");

        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      }
    })();
  }, [invitation?.invitationId]);

  if (isLoading) {
    return <Spinner loading className="fullViewSpinner" size={54} />;
  }

  if (invitation?.studentAccountEmail) {
    return (
      <ExistingAccount
        invitationId={invitation.invitationId}
        studentAccountEmail={invitation.studentAccountEmail}
      />
    );
  }

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Witaj</div>
          <div className={cs.subtitle}>
            <strong className="semi-bold">{invitation?.tutorName}</strong>{" "}
            zaprasza Cię do wspólnej nauki w aplikacji. Podaj swoje dane aby
            założyć konto.
          </div>
        </header>

        <form onSubmit={handleSubmit}>
          <Input
            value={firstName}
            label="Imię"
            required
            onChange={setFirstName}
          />
          <Input
            value={lastName}
            label="Nazwisko"
            required
            onChange={setLastName}
          />
          <Input
            value={email}
            label="Adres e-mail"
            type="email"
            required
            onChange={setEmail}
          />
          <Input
            value={password}
            label="Hasło"
            type="password"
            required
            onChange={setPassword}
          />
          <Input
            value={passwordCofirm}
            label="Potwierdź hasło"
            type="password"
            required
            ref={confirmPasswordElement}
            onChange={setPasswordCofirm}
          />

          <div className="text-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
            >
              Utwórz konto
            </Button>
          </div>
        </form>

        <div className={cs.existingAccountText}>
          <span>Posiadasz już konto? </span>

          <Link
            to={`/login?invitation=${params.invitationId}`}
            className="link"
          >
            Zaloguj się
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    await AuthApi.register({
      firstName,
      lastName,
      email,
      password,
      studentId: params.invitationId,
    });

    window.location.href = "/";
  }

  function validatePassword() {
    if (password !== passwordCofirm) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }
};
