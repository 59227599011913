import React, { forwardRef } from "react";
import DatePickerBase, {
  registerLocale,
  setDefaultLocale,
} from "react-datepicker";
import pl from "date-fns/locale/pl";
import { Input } from "../Input";
import { ReactComponent as CalendarImg } from "../../img/calendar_2.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.global.scss";
import cs from "./DatePicker.module.scss";

registerLocale("pl", pl);
setDefaultLocale("pl");

const InputWrapper = forwardRef(
  ({ value, label, inputRef, required, onClick }: any, ref: any) => (
    <span ref={ref} className={cs.InputWrapper}>
      <CalendarImg width={16} className={cs.img} onClick={onClick} />

      <Input
        value={value}
        label={label}
        className={cs.input}
        required={required}
        onChange={() => {}}
        ref={inputRef}
        onClick={onClick}
      />
    </span>
  ),
);

type DatePickerProps = {
  value?: Date | null;
  label?: string;
  mode?: "date" | "datetime" | "time";
  required?: boolean;
  minDate?: Date | null;
  maxDate?: Date | null;
  minHour?: Date;
  maxHour?: Date;
  onChange: (date: Date) => void;
};

const DEFAULT_MIN_HOUR = new Date(new Date().setHours(6, 0, 0, 0));
const DEFAULT_MAX_HOUR = new Date(new Date().setHours(23, 0, 0, 0));

export const DatePicker = forwardRef(
  (
    {
      value,
      label,
      required,
      mode,
      minDate,
      maxDate,
      minHour = DEFAULT_MIN_HOUR,
      maxHour = DEFAULT_MAX_HOUR,
      onChange,
    }: DatePickerProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <DatePickerBase
        selected={value}
        customInput={<InputWrapper label={label} inputRef={ref} />}
        calendarClassName={cs.calendar}
        showTimeSelect={mode === "time" || mode === "datetime"}
        showTimeInput={mode === "datetime"}
        timeIntervals={5}
        timeCaption="Godzina"
        timeInputLabel="Godzina"
        showTimeSelectOnly={mode === "time"}
        dateFormat={mode === "datetime" ? "Pp" : mode === "time" ? "p" : "P"}
        required={required}
        portalId="root-portal"
        minTime={minHour}
        maxTime={maxHour}
        minDate={minDate}
        maxDate={maxDate}
        onCalendarOpen={() => {
          if (mode === "time" && value) {
            setTimeout(() => {
              const selectedTimeEl = document.querySelector(
                ".react-datepicker__time-list-item--selected",
              );

              if (selectedTimeEl) {
                selectedTimeEl.scrollIntoView({
                  block: "nearest",
                  inline: "start",
                });
              }
            });
          }
        }}
        onChange={onChange}
      />
    );
  },
);
