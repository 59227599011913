import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import classNames from "classnames";
import { isSmallScreen } from "@utils/utils";
import expandDownImg from "./img/expand_down.svg";
import cs from "./Menu.module.scss";

type MenuItemProps = {
  text: string;
  link: string;
  icon: any;
  subItems?: { text: string; link: string }[];
};

export const MenuItem = ({ text, link, icon, subItems }: MenuItemProps) => {
  const [expanded, setExpanded] = useState(false);

  const location = useLocation();
  const isActive =
    !expanded &&
    (link === location.pathname ||
      subItems?.some(({ link }) => link === location.pathname));

  const rootCs = classNames({ [cs.expanded]: expanded });
  const itemCs = classNames(cs.menuItem, {
    [cs.menuItem__active]: isActive,
  });

  // albo link się zgadza i nie jest expanded

  return (
    <div className={rootCs}>
      <NavLink
        className={itemCs}
        to={link}
        onClick={(e) => {
          if (subItems) {
            setExpanded(true);

            if (isSmallScreen) {
              e.stopPropagation();
              e.preventDefault();
            }
          }
        }}
      >
        <img src={icon} alt="" />
        <span>{text}</span>

        {subItems && (
          <button
            className={`${cs.expandIcon} btn-icon`}
            type="button"
            onClick={toggleSubMenu}
          >
            <img src={expandDownImg} alt="" />
          </button>
        )}
      </NavLink>

      {subItems && expanded && (
        <div className={cs.subItems}>
          {subItems.map((subItem: any) => (
            <NavLink to={subItem.link} className={cs.menuSubItem}>
              {subItem.text}
            </NavLink>
          ))}
        </div>
      )}
    </div>
  );

  function toggleSubMenu(e: React.MouseEvent) {
    e.stopPropagation();
    e.preventDefault();

    setExpanded(!expanded);
  }
};
