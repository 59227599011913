import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import { AuthApi } from "@api/AuthApi";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Page } from "../components/Page";
import cs from "./RegisterPage.module.scss";

export const RegisterPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCofirm, setPasswordCofirm] = useState("");

  const confirmPasswordElement = useRef<HTMLInputElement>(null);

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Witaj</div>
          <div className={cs.subtitle}>Rejestrujesz się jako student</div>
        </header>

        <form onSubmit={handleSubmit}>
          <Input
            value={firstName}
            label="Imię"
            required
            onChange={setFirstName}
          />
          <Input
            value={lastName}
            label="Nazwisko"
            required
            onChange={setLastName}
          />
          <Input
            value={email}
            label="Adres e-mail"
            type="email"
            required
            onChange={setEmail}
          />
          <Input
            value={password}
            label="Hasło"
            type="password"
            required
            onChange={setPassword}
          />
          <Input
            value={passwordCofirm}
            label="Potwierdź hasło"
            type="password"
            required
            ref={confirmPasswordElement}
            onChange={setPasswordCofirm}
          />

          <div className="text-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
            >
              Utwórz konto
            </Button>
          </div>
        </form>

        <div className={cs.existingAccountText}>
          <span>Posiadasz już konto? </span>

          <Link to="/login" className="link">
            Zaloguj się
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.MouseEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!validatePassword()) {
      return;
    }

    await AuthApi.register({
      firstName,
      lastName,
      email,
      password,
    });

    window.location.href = "/";
  }

  function validatePassword() {
    if (password !== passwordCofirm) {
      confirmPasswordElement.current?.setCustomValidity(
        "Hasła się nie zgadzają",
      );

      return false;
    }

    confirmPasswordElement.current?.setCustomValidity("");
    return true;
  }
};
