import { useQuery } from "react-query";
import { CalendarApi } from "../api/CalendarApi";
import { DateType } from "../types/DateType";

export const useCalendarEvents = (startDate: DateType, endDate: DateType) => {
  return useQuery(
    ["calendar", startDate, endDate],
    () => CalendarApi.fetchAll(startDate, endDate),
    {
      keepPreviousData: true,
      enabled: Boolean(startDate) && Boolean(endDate),
    },
  );
};
