import React from "react";
import cs from "./SidePanel.module.scss";

type SidePanelProps = {
  actions: React.ReactNode;
  main: React.ReactNode;
  onClose: () => void;
};

export const SidePanel = ({ actions, main, onClose }: SidePanelProps) => {
  return (
    <aside className={`SidePanel ${cs.root}`}>
      <header className={cs.topBar}>
        <button
          className={cs.closeBtn}
          title="Zamknij panel boczny"
          onClick={onClose}
        >
          X
        </button>
      </header>

      <div className={cs.main}>
        <div className={cs.actions}>{actions}</div>

        <div className={cs.info}>{main}</div>
      </div>
    </aside>
  );
};
