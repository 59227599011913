import produce from "immer";
import axios from "axios";

type WithId = {
  _id: string;
};

export function deleteById<Type extends WithId>(
  id: string,
  collection: Type[],
) {
  const copy = [...collection];

  const index = copy.findIndex(({ _id }) => _id === id);

  if (index !== -1) {
    copy.splice(index, 1);
  }

  return copy;
}

export function findById<Type extends WithId>(id: string, collection: Type[]) {
  return collection.find(({ _id }) => _id === id);
}

export function deleteByIdShallow(id: string, collection: WithId[]) {
  const index = collection.findIndex(({ _id }) => _id === id);

  if (index !== -1) {
    collection.splice(index, 1);
  }

  return collection;
}

export function genRandomId() {
  return String(Math.ceil(Math.random() * 1e6));
}

export function withRandomId(collection: any[]) {
  return collection.map((item) => ({ ...item, _id: genRandomId() }));
}

export function replaceWithId(collection: WithId[], updatedItem: WithId) {
  return produce(collection, (draft) => {
    const index = draft.findIndex(({ _id }) => _id === updatedItem._id);

    draft[index] = updatedItem;
  });
}

export async function downloadFile(fileUrl: string, parent?: HTMLElement) {
  const response = await axios({
    url: fileUrl,
    method: "GET",
    responseType: "blob",
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;

  const contentDisposition = response.headers["content-disposition"];

  if (!contentDisposition) return;

  const fileName = decodeURIComponent(
    contentDisposition.split("''")[1].replace(";", ""),
  );
  link.setAttribute("download", fileName);

  (parent || document.body).appendChild(link);
  link.click();
  (parent || document.body).removeChild(link);
}

export async function openLink(link: string, parent?: HTMLElement) {
  const anchorEl = document.createElement("a");
  anchorEl.href = link;
  anchorEl.target = "_blank";

  (parent || document.body).appendChild(anchorEl);
  anchorEl.click();
  (parent || document.body).removeChild(anchorEl);
}

export const isSmallScreen = window.innerWidth < 768;
export const isTabletScreen = window.innerWidth < 1200;
