import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ToastService } from "@services/ToastService";
import { useSendResetPasswordEmail } from "@hooks/useSendResetPasswordEmail";
import { Input } from "@components/Input";
import { Button } from "@components/Button";
import { Page } from "../components/Page";
import cs from "./ResetPasswordPage.module.scss";

export const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");

  const emailInputElement = useRef<any>(null);

  const sendResetPasswordEmail = useSendResetPasswordEmail();

  return (
    <Page>
      <Page.Box>
        <header>
          <div className={cs.title}>Zresetuj hasło</div>
        </header>

        <form onSubmit={handleSubmit}>
          <div>
            <Input
              value={email}
              label="Adres e-mail"
              type="email"
              required
              ref={emailInputElement}
              onChange={(val) => {
                setEmail(val);
                emailInputElement.current.setCustomValidity("");
              }}
            />

            <div className={cs.helpText}>
              Podaj adres email użyty przy rejestracji konta
            </div>
          </div>

          <div className="f-center mt24">
            <Button
              color="secondary"
              className={cs.submitBtn}
              htmlType="submit"
              isLoading={sendResetPasswordEmail.isLoading}
            >
              Resetuj
            </Button>
          </div>
        </form>

        <div className={cs.noAccountText}>
          <Link to="/login" className="link">
            Wróć do strony logowania
          </Link>
        </div>
      </Page.Box>
    </Page>
  );

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      await sendResetPasswordEmail.mutateAsync({
        email,
      });

      setEmail("");

      ToastService.success(
        "Email z linkiem umożliwiającym ustawienie nowego hasła został wysłany.",
        { duration: 5000 },
      );
    } catch (error: any) {
      if (error.message === "USER_NOT_FOUND") {
        emailInputElement.current.setCustomValidity(
          "Konto studenta o tym adresie email nie istnieje.",
        );
        emailInputElement.current?.reportValidity();
      } else {
        ToastService.generalError();
      }
    }
  }
};
