//@ts-nocheck

import { useLayoutEffect, useState } from "react";
import { useImmer } from "use-immer";
import classNames from "classnames";
import { formatDate, formatShortDate } from "@utils/date.utils";
import { Checkbox } from "@components/Checkbox";
import { Spinner } from "@components/Spinner";
import emptyImg from "./img/empty.png";
import sortUpImg from "./img/sort_up.svg";
import sortDownImg from "./img/sort_down.svg";
import sortImg from "./img/sort.svg";
import messageImg from "./img/message.svg";
import phoneImg from "./img/phone.svg";
import arrowLeftImg from "./img/arrow_left.svg";
import arrowsLeftImg from "./img/arrows_left.svg";
import arrowRightImg from "./img/arrow_right.svg";
import arrowsRightImg from "./img/arrows_right.svg";
import "./Table.scss";
import { InfoBox } from "../InfoBox";

type TableProps = Partial<{
  columns: any[];
  data: any[];
  sort: any;
  empty: any;
  className: string;
  isLoading?: boolean;
  onSort: any;
  onRowClick: any;
}>;

export const Table = ({
  columns,
  data,
  sort,
  empty = {
    title: "Ta lista jest pusta",
  },
  className = "",
  isLoading,
  onSort,
  onRowClick,
}: TableProps) => {
  const rootCs = classNames("Table", className);

  const rows = data ?? [];

  useWindowSize();

  if (isLoading) {
    return (
      <div className={rootCs}>
        <Spinner loading className="Table_spinner" size={44} />
      </div>
    );
  }

  return (
    <div className={rootCs}>
      {data?.length > 0 ? (
        <table className="Table_content">
          <thead>
            <tr className="Table_row--head">
              {columns.map((col) => {
                const style = {};

                if (col.width) {
                  style.width = col.width;
                }
                if (col.hideWidth >= window.innerWidth) {
                  return null;
                }

                const thCs = classNames("Table_th", {
                  "Table_th--sortable": col.sortable,
                  "Table_th--center": col.center,
                });

                let sortIcon = sortImg;

                if (isSortedUp(col.field)) {
                  sortIcon = sortUpImg;
                } else if (isSortedDown(col.field)) {
                  sortIcon = sortDownImg;
                }
                return (
                  <th
                    key={col.title}
                    className={thCs}
                    style={style}
                    onClick={() => {
                      handleTableHeaderClicked(col);
                    }}
                  >
                    <div className="Table_th-container">
                      <span>{col.title}</span>

                      {col.help && (
                        <span
                          className="f-center"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <InfoBox desc={col.help} className="ml4 mr4" />
                        </span>
                      )}

                      {col.sortable && (
                        <div className="Table_th-sort">
                          <img src={sortIcon} alt="" />
                        </div>
                      )}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => {
              const rowCs = classNames("Table_row--body", {
                "Table_row--clickable": Boolean(onRowClick),
              });

              return (
                <tr
                  key={row._id}
                  className={rowCs}
                  onClick={() => handleRowClicked(row)}
                >
                  {columns.map((col) => {
                    if (col.hideWidth >= window.innerWidth) {
                      return null;
                    }

                    const cellValue = row[col.field];

                    const tdCs = classNames("Table_td", {
                      "text-center": col.center,
                    });

                    if (typeof col.render === "function") {
                      return (
                        <td key={col.title} className={tdCs}>
                          {col.render(cellValue, col, row)}
                        </td>
                      );
                    }

                    if (col.type === "date") {
                      if (!cellValue) {
                        return (
                          <td key={col.title} className={tdCs}>
                            -
                          </td>
                        );
                      }

                      const dateFormat = col.typeArgs.dateStyle;

                      const date = dateFormat
                        ? formatDate(cellValue, dateFormat)
                        : formatShortDate(cellValue);

                      return (
                        <td key={col.title} className={tdCs}>
                          {date}
                        </td>
                      );
                    }

                    if (col.type === "email") {
                      return (
                        <td
                          key={col.title}
                          className="Table_td Table_td--email"
                        >
                          <span>
                            <img src={messageImg} alt="" />

                            {!cellValue && "-"}

                            <a href={`mailto:${cellValue}`} className="link">
                              {cellValue}
                            </a>
                          </span>
                        </td>
                      );
                    }

                    if (col.type === "phone") {
                      return (
                        <td
                          key={col.title}
                          className="Table_td Table_td--email"
                        >
                          <span>
                            <img src={phoneImg} alt="" />

                            <span>{cellValue || "-"}</span>
                          </span>
                        </td>
                      );
                    }

                    if (col.type === "currency") {
                      if (!cellValue) {
                        return (
                          <td key={col.title} className="Table_td">
                            brak
                          </td>
                        );
                      }

                      return (
                        <td key={col.title} className="Table_td">
                          {cellValue} PLN
                        </td>
                      );
                    }

                    if (col.type === "boolean") {
                      return (
                        <td key={col.title} className="Table_td text-center">
                          <Checkbox checked={cellValue} disabled />
                        </td>
                      );
                    }

                    return (
                      <td
                        key={col.title}
                        className={tdCs}
                        onClick={(e) => {
                          const target = e.target as HTMLElement;

                          const { whiteSpace } = getComputedStyle(
                            target as HTMLElement,
                          );

                          if (whiteSpace === "nowrap") {
                            target.style.whiteSpace = "normal";
                          } else {
                            target.style.whiteSpace = "nowrap";
                          }
                        }}
                      >
                        {cellValue}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="Table--empty">
          <img src={emptyImg} alt="List is empty" />
          <p className="h5">{empty.title}</p>
          <p className="caption">{empty.sub}</p>

          {empty.extra || null}
        </div>
      )}

      <div className="Table_pagination">
        <button className="btn-icon">
          <img src={arrowsLeftImg} alt="" />
        </button>

        <button className="btn-icon">
          <img src={arrowLeftImg} alt="" />
        </button>

        <button className="btn-icon">
          <img src={arrowRightImg} alt="" />
        </button>

        <button className="btn-icon">
          <img src={arrowsRightImg} alt="" />
        </button>
      </div>
    </div>
  );

  function handleRowClicked(row: any) {
    if (typeof onRowClick === "function") {
      onRowClick(row);
    }
  }

  function handleTableHeaderClicked(col) {
    if (!col.sortable) return;

    onSort?.(col.field);
  }

  function isSortedUp(field: string) {
    return sort.includes(field);
  }

  function isSortedDown(field: string) {
    return sort.includes(`-${field}`);
  }
};

type UseSortReturnType = [string[], (field: string) => void];

Table.useSort = (): UseSortReturnType => {
  const [sort, setSort] = useImmer([]);

  return [sort, sortByField];

  function sortByField(field: string) {
    setSort([]);

    // NOTE: Sorted up -> no sorting
    if (sort.includes(field)) {
      setSort((prev) => prev.filter((col) => col !== field));

      // NOTE: Sorted down -> sort up
    } else if (sort.includes(`-${field}`)) {
      setSort((prev) => prev.filter((col) => col !== `-${field}`));
      setSort((prev) => [...prev, field]);
    } else {
      // NOTE: Not sorted -> sort down
      setSort((prev) => [...prev, `-${field}`]);
    }
  }
};

Table.defaultProps = {
  sort: [],
};

const useWindowSize = (): number[] => {
  const [windowSize, setWindowSize] = useState([0, 0]);
  const updateWindowSize = () => {
    setWindowSize([window.innerWidth, window.innerHeight]);
  };

  useLayoutEffect(() => {
    window.addEventListener("resize", updateWindowSize);
    updateWindowSize();

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);
  return [windowSize[0], windowSize[1]];
};
