import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ParticipantType } from "../types/ParticipantType";

type ConfirmationModal = {
  confirmationModal: {
    isOpen: boolean;
    text?: string;
    confirmText?: string;
    onConfirm?: () => void;
    onCancel?: () => void;
  };
  closeConfirmationModal: () => void;
  openConfirmationModal: ({
    text,
    confirmText,
  }: {
    text?: string;
    confirmText?: string;
  }) => Promise<boolean>;
};

type Store = ConfirmationModal & {
  participants: ParticipantType[];
  setParticipants: (participants: ParticipantType[]) => void;
};

const useAppStore = create<Store>()(
  devtools((set, get) => ({
    // PARTICIPANTS
    participants: [],
    setParticipants: (participants) =>
      set({ participants }, false, "setParticipants"),
    // END - PARTICIPANTS

    // CONFIRMATION MODAL
    confirmationModal: {
      isOpen: false,
    },
    closeConfirmationModal: () => {
      // NOTE: For better visual effect - keep labels
      //       the same until closed.
      set(
        (prev) => {
          return {
            confirmationModal: {
              ...prev.confirmationModal,
              isOpen: false,
            },
          };
        },
        false,
        "closeConfirmationModal1",
      );

      setTimeout(() => {
        set(
          { confirmationModal: { isOpen: false } },
          false,
          "closeConfirmationModal2",
        );
      }, 400);
    },
    openConfirmationModal: ({ text, confirmText } = {}) => {
      return new Promise((resolve) => {
        set(
          {
            confirmationModal: {
              isOpen: true,
              text,
              confirmText,
              onConfirm: () => {
                resolve(true);
                get().closeConfirmationModal();
              },
              onCancel: () => {
                resolve(false);
                get().closeConfirmationModal();
              },
            },
          },
          false,
          "openConfirmationModal",
        );
      });
    },
    // END - CONFIRMATION MODAL
  })),
);

const { getState } = useAppStore;

export { useAppStore, getState };
