import { useEffect, useState } from "react";
import { useCalendarEvents } from "@hooks/useCalendarEvents";
import {
  addDays,
  addMonths,
  endOf,
  formatMonth,
  formatWeekday,
  getDatesInRange,
  isToday,
  startOf,
  subtractDays,
  subtractMonths,
} from "@utils/date.utils";
import { MonthView } from "./MonthView";
import { WeekView } from "./WeekView";
import { ViewPicker } from "./ViewPicker";
import { ReactComponent as ArrowLeftImg } from "./img/arrow_left.svg";
import { ReactComponent as ArrowRightImg } from "./img/arrow_right.svg";
import cs from "./CalendarPage.module.scss";

export type ViewType = "workweek" | "week" | "month";

const TODAY = new Date();

export const CalendarPage = () => {
  const [selectedDate, setSelectedDate] = useState(TODAY);
  const [startDate, setStartDate] = useState(startOf(selectedDate, "week"));
  const [endDate, setEndDate] = useState(
    subtractDays(endOf(selectedDate, "week"), 2),
  );
  const [currentView, setCurrentView] = useState<ViewType>("workweek");

  const month = formatMonth(selectedDate, "long");

  const { data = [] } = useCalendarEvents(startDate, endDate);

  useEffect(() => {
    if (currentView === "week") {
      setStartDate(startOf(selectedDate, "week"));
      setEndDate(endOf(selectedDate, "week"));
    } else if (currentView === "workweek") {
      setStartDate(startOf(selectedDate, "week"));
      setEndDate(subtractDays(endOf(selectedDate, "week"), 2));
    } else if (currentView === "month") {
      setStartDate(startOf(selectedDate, "month"));
      setEndDate(endOf(selectedDate, "month"));
    }
  }, [selectedDate, currentView]);

  const datesRange = getDatesInRange(startDate, endDate);

  return (
    <div className={cs.page}>
      <section className={cs.calendar}>
        <header className={cs.header}>
          <div className={cs.currentDate}>
            <span>
              {currentView !== "month" && (
                <span>
                  {startDate.getDate()} - {endDate.getDate()}{" "}
                </span>
              )}

              <span>
                {month} {selectedDate.getFullYear()}
              </span>
            </span>
          </div>

          <div className={cs.dateNavigator}>
            <button className="btn-icon" onClick={handlePrevDate}>
              <ArrowLeftImg width={14} />
            </button>

            <button className="btn-icon" onClick={handleReturnToToday}>
              <span className={cs.dateNavigator_today}>Dzisiaj</span>
            </button>

            <button className="btn-icon" onClick={handleNextDate}>
              <ArrowRightImg width={14} />
            </button>
          </div>

          <div className="ml-auto">
            <ViewPicker selected={currentView} onChange={setCurrentView} />
          </div>
        </header>

        {currentView !== "month" && (
          <section className={cs.weekdayNames} data-cols={datesRange.length}>
            {datesRange.map((date) => {
              let className = cs.weekdayName;

              if (isToday(date)) {
                className += " " + cs.weekdayName__today;
              }

              return (
                <div key={date.getDate()} className={className}>
                  {formatWeekday(date, "short") + " " + date.getDate()}
                </div>
              );
            })}
          </section>
        )}

        {currentView !== "month" && (
          <WeekView startDate={startDate} endDate={endDate} events={data} />
        )}

        {currentView === "month" && (
          <MonthView currentDate={selectedDate} events={data} />
        )}
      </section>
    </div>
  );

  function handlePrevDate() {
    setSelectedDate((prevDate) => {
      if (currentView === "month") {
        return subtractMonths(selectedDate, 1);
      }

      return subtractDays(selectedDate, 7);
    });
  }

  function handleNextDate() {
    setSelectedDate((prevDate) => {
      if (currentView === "month") {
        return addMonths(selectedDate, 1);
      }

      return addDays(selectedDate, 7);
    });
  }

  function handleReturnToToday() {
    setSelectedDate(new Date());
  }
};
