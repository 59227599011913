import { useEffect, useState } from "react";
import { useImmer } from "use-immer";
import { useAppStore } from "@store";
import { useLessons } from "@hooks/useLessons";
import { useUploadHomework } from "@hooks/useUploadHomework";
import { useDeleteLesson } from "@hooks/useDeleteLesson";
import { useDeleteHomework } from "@hooks/useDeleteHomework";
import { LessonType } from "@customTypes/LessonType";
import { Table } from "@components/Table";
import { SidePanel } from "./SidePanel";
import { columns } from "./columns";
import cs from "./LessonsListPage.module.scss";

export const LessonsListPage = () => {
  const [activeLesson, setActiveLesson] = useState<LessonType | null>(null);
  const [filters] = useImmer<any>({
    dateRange: [null, null],
  });

  const [sort, handleSort] = Table.useSort();

  const { data = [], isLoading } = useLessons(filters, Array.from(sort));

  const deleteLesson = useDeleteLesson();
  const uploadHomework = useUploadHomework();
  const deleteHomework = useDeleteHomework();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  useEffect(() => {
    // NOTE: Check if active lesson hasn't changed after data was updated
    if (activeLesson) {
      const updatedActiveLesson = data.find(
        (lesson) => lesson._id === activeLesson._id,
      );

      setActiveLesson(updatedActiveLesson || null);
    }
  }, [data, activeLesson]);

  return (
    <main className={cs.page}>
      <header className={cs.header} />

      <section className={cs.table}>
        <Table
          data={data}
          columns={columns}
          sort={sort}
          isLoading={isLoading}
          onSort={handleSort}
          onRowClick={setActiveLesson}
        />
      </section>

      <SidePanel
        activeLesson={activeLesson}
        onClose={() => setActiveLesson(null)}
        onCancel={handleCancel}
        onUploadHomework={async (file) => {
          if (!activeLesson) return;

          await uploadHomework.mutateAsync({
            lessonId: activeLesson?._id,
            file,
          });
        }}
        onDeleteHomework={async (homeworkId) => {
          if (!activeLesson) return;

          await deleteHomework.mutateAsync({
            lessonId: activeLesson?._id,
            homeworkId,
          });
        }}
      />
    </main>
  );

  async function handleCancel() {
    const isConfirmed = await openConfirmationModal({
      confirmText: "Odwołaj",
      text: `Czy na pewno chcesz odwołać tę lekcję?`,
    });

    if (isConfirmed && activeLesson) {
      await deleteLesson.mutateAsync({ lessonId: activeLesson._id });
      setActiveLesson(null);
    }
  }
};
