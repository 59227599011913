import groupBy from "lodash.groupby";
import { LessonDtoService } from "./LessonDtoService";
import { TimeSlotDtoService } from "./TimeSlotDtoService";
import { CalendarEventType } from "../constants/CalendarEventType";
import { CalendarEvent } from "../types/CalendarEvent";
import { TimeSlotType } from "../types/TimeSlotType";
import { CalendarEventsDto } from "../types/CalendarEventsDto";

export class CalendarEventsDtoService {
  /**
   * Returns events grouped by date like this:
   *
   * [{
   *  12.01.2022: [CalendarEvent_1, CalendarEvent_2, CalendarEvent_3],
   *  13.01.2022: [CalendarEvent_4, CalendarEvent_5, CalendarEvent_6]
   * }]
   */
  static transform(calendarEventsDto: CalendarEventsDto) {
    const events: CalendarEvent[] = [];

    // NOTE: Lessons
    calendarEventsDto.lessons.forEach((lessonDto: any) => {
      const lesson = LessonDtoService.transform(lessonDto);
      const lessonAsEvent = CalendarEventsDtoService.lessonToEvent(lesson);

      events.push(lessonAsEvent);
    });

    // NOTE: Time slots
    calendarEventsDto.timeSlots.forEach((timeSlotDto: any) => {
      const timeSlot = TimeSlotDtoService.transform(timeSlotDto);
      const timeSlotAsEvent =
        CalendarEventsDtoService.timeSlotToEvent(timeSlot);

      events.push(timeSlotAsEvent);
    });

    return groupBy(events, "startDate");
  }

  private static lessonToEvent(lesson: any) {
    return new CalendarEvent(
      lesson.date,
      lesson.duration,
      CalendarEventType.lesson,
      lesson,
    );
  }

  private static timeSlotToEvent(timeSlot: TimeSlotType) {
    return new CalendarEvent(
      timeSlot.date,
      timeSlot.duration,
      CalendarEventType.timeSlot,
      timeSlot,
    );
  }
}
