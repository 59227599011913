import { ReactNode } from "react";
import cs from "./RadioButton.module.scss";

type RadioButtonProps = {
  value?: any;
  checked?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: (selected: RadioButtonProps["value"]) => void;
};

export const RadioButton = ({
  value,
  checked,
  label,
  name = "radio",
  onChange,
}: RadioButtonProps) => {
  return (
    <label className={cs.root}>
      <input
        type="radio"
        checked={checked}
        name={name}
        onChange={() => onChange?.(value)}
      />

      {label && <span>{label}</span>}
    </label>
  );
};
