import { useAppStore } from "@store";
import { useTutors } from "@hooks/useTutors";
import { useDeleteTutor } from "@hooks/useDeleteTutor";
import { isSmallScreen } from "@utils/utils";
import { Table } from "@components/Table";
import { columns } from "./columns";
import { columnsMobile } from "./columnsMobile";
import cs from "./MyTutorsPage.module.scss";

export const MyTutorsPage = () => {
  const { data = [], isLoading } = useTutors();
  const deleteStudent = useDeleteTutor();

  const openConfirmationModal = useAppStore(
    (state) => state.openConfirmationModal,
  );

  return (
    <main className={cs.page}>
      <header className={cs.header} />

      <Table
        data={data}
        columns={(isSmallScreen ? columnsMobile : columns)({
          onDeleteTutor: handleDelete,
        })}
        isLoading={isLoading}
      />
    </main>
  );

  async function handleDelete(tutorId: string) {
    const isConfirmed = await openConfirmationModal({
      text: "Czy na pewno chcesz zrezygnować ze wspólnej nauki? Tej akcji nie można cofnąć.",
      confirmText: "Zrezygnuj",
    });

    if (isConfirmed) {
      deleteStudent.mutate({ tutorId });
    }
  }
};
