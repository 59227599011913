import { DateTime, DateTimeUnit, DurationUnits } from "luxon";
import { DateType } from "../types/DateType";

export function formatShortDate(date: DateType) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.toLocaleString({
    day: "numeric",
    month: "numeric",
  });
}

export function formatDate(
  date: DateType,
  style: "numeric" | "long" | "full" | "iso" = "numeric",
) {
  const dt = DateTime.fromJSDate(new Date(date));

  if (style === "full") {
    return dt.toLocaleString({
      dateStyle: "full",
    });
  }

  if (style === "numeric") {
    return dt.toLocaleString({
      day: "numeric",
      month: "numeric",
      year: "numeric",
    });
  }

  if (style === "long") {
    return dt.toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }

  if (style === "iso") {
    return dt.toISODate();
  }

  return "invalid_date";
}

export function formatHour(date: DateType) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.toLocaleString({
    timeStyle: "short",
  });
}

export function formatWeekday(
  date: DateType,
  format: "long" | "short" | "narrow" = "short",
) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.toLocaleString({
    weekday: format,
  });
}
export function formatMonth(
  date: DateType,
  format: "long" | "short" | "narrow" = "short",
) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.toLocaleString({
    month: format,
  });
}

export function addMonths(date: DateType, noOfMonthsToAdd: number) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.plus({ month: noOfMonthsToAdd }).toJSDate();
}

export function addDays(date: DateType, noOfDaysToAdd: number) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.plus({ day: noOfDaysToAdd }).toJSDate();
}

export function addMinutes(date: DateType, noOfMinutesToAdd: number) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.plus({ minutes: noOfMinutesToAdd }).toJSDate();
}

export function subtractDays(date: DateType, noOfDaysToSubtract: number) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.minus({ day: noOfDaysToSubtract }).toJSDate();
}

export function subtractMonths(date: DateType, noOfMonthsToSubtract: number) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.minus({ month: noOfMonthsToSubtract }).toJSDate();
}

export function toRelative(date: DateType) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.toRelativeCalendar();
}

export function diff(
  date1: DateType,
  date2: DateType,
  unit: DurationUnits = "days",
) {
  const dt1 = DateTime.fromJSDate(new Date(date1));
  const dt2 = DateTime.fromJSDate(new Date(date2));

  return Math.round(dt1.diff(dt2, unit)[unit]);
}

export function diffMinutes(date1: DateType, date2: DateType) {
  const dt1 = DateTime.fromJSDate(new Date(date1)).set({
    second: 0,
    millisecond: 0,
  });
  const dt2 = DateTime.fromJSDate(new Date(date2)).set({
    second: 0,
    millisecond: 0,
  });

  return dt1.diff(dt2, "minutes").minutes;
}

export function isBetween(date: DateType, range: [DateType, DateType]) {
  let [start, end] = range.sort();

  start = new Date(start);
  end = new Date(end);
  date = new Date(date);

  return date >= start && date <= end;
}

export function startOf(date: DateType, unit: DateTimeUnit = "day") {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.startOf(unit).toJSDate();
}

export function endOf(date: DateType, unit: DateTimeUnit = "day") {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.endOf(unit).toJSDate();
}

export function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function isToday(date: DateType) {
  const dt = DateTime.fromJSDate(new Date(date));

  return dt.hasSame(DateTime.local(), "day");
}

export function isCurrentHour(hour: number, date: DateType = new Date()) {
  const dt = DateTime.fromJSDate(new Date(date)).set({ hour });
  const now = DateTime.now();

  return dt.hasSame(now, "day") && dt.hasSame(now, "hour");
}

export const TODAY_START = startOf(new Date());
export const TODAY_END = endOf(new Date());
