// @ts-nocheck
import { useEffect, useState } from "react";
import { useCalendarEvents } from "@hooks/useCalendarEvents";
import {
  addDays,
  endOf,
  formatDate,
  formatWeekday,
  startOf,
  subtractDays,
} from "@utils/date.utils";
import { CalendarEvent } from "@customTypes/CalendarEvent";
import { Event } from "./Event";
import arrowForwardImg from "./img/arrow_forward.svg";
import arrowBackImg from "./img/arrow_back.svg";
import cs from "./CalendarSingleDay.module.scss";

const TODAY = new Date();
const startHour = 6;
const endHour = 22;

function getFreshCalendarHours(date: Date) {
  return new Array(endHour - startHour + 1).fill(1).map((el, index) => {
    const hour = index + startHour;
    const dateCopy = new Date(date);

    return {
      hour,
      date: new Date(dateCopy.setHours(hour, 0, 0, 0)),
    };
  });
}

export const CalendarSingleDay = ({ selectedDay, onSelectedDayChange }) => {
  const [calendarHours, setCalendarHours] = useState(() => {
    return getFreshCalendarHours(selectedDay);
  });

  const { data = null } = useCalendarEvents(
    startOf(selectedDay),
    endOf(selectedDay),
  );

  useEffect(() => {
    if (!data || !selectedDay) return;

    const selectedDayAsIso = formatDate(selectedDay, "iso");

    const selectedDayEvents = data[selectedDayAsIso];

    if (!selectedDayEvents?.length) return;

    // TODO: Use immer
    const calendarHoursCopy = getFreshCalendarHours(selectedDay);

    selectedDayEvents.forEach((event: any) => {
      const relatedHour = calendarHoursCopy.find(
        (calendarHour) => event.startHour === calendarHour.hour,
      ) as any;

      if (!relatedHour) return;

      // TODO:
      relatedHour.data = [...(relatedHour.data || []), event];
    });

    setCalendarHours(calendarHoursCopy);
  }, [data, selectedDay]);

  return (
    <div className={cs.root}>
      <div className={cs.header}>
        <span className="v-center">
          <span className={cs.header__title}>Kalendarz</span>
        </span>

        <span className={cs.header__date}>
          <p>{formatWeekday(selectedDay, "long")},</p>
          <p>{formatDate(selectedDay, "long")}</p>
        </span>
      </div>

      <div className={cs.subheader}>
        <button className="btn-icon" onClick={handleGoPrev}>
          <img src={arrowBackImg} alt="" />
        </button>

        <button className="btn-icon" onClick={() => onSelectedDayChange(TODAY)}>
          Dzisiaj
        </button>

        <button className="btn-icon" onClick={handleGoNext}>
          <img src={arrowForwardImg} alt="" />
        </button>
      </div>

      <div className={cs.days}>
        {calendarHours.map((calendarHour: any, index) => (
          <div
            key={calendarHour.hour}
            className={cs.day}
            style={{ zIndex: 30 - index }}
          >
            <div className={cs.day__hour}>
              <span className={cs.hour}>{calendarHour.hour}</span>
            </div>

            <div className={cs.day__content}>
              {calendarHour.data?.map((event: CalendarEvent) => (
                <Event key={event.details._id} event={event} />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  function handleGoPrev() {
    onSelectedDayChange(subtractDays(selectedDay, 1));
  }

  function handleGoNext() {
    onSelectedDayChange(addDays(selectedDay, 1));
  }
};
