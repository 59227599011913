import { CalendarEvent } from "../../types/CalendarEvent";
import { CalendarEventType } from "../../constants/CalendarEventType";
import { CalendarLessonEvent } from "../CalendarLessonEvent";
import { CalendarTimeSlotEvent } from "../CalendarTimeSlotEvent";

export const Event = ({ event }: { event: CalendarEvent }) => {
  switch (event.type) {
    case CalendarEventType.lesson:
      return (
        <CalendarLessonEvent key={event.details._id} lesson={event.details} />
      );
    case CalendarEventType.timeSlot:
      return (
        <CalendarTimeSlotEvent
          key={event.details._id}
          timeSlot={event.details}
        />
      );
    default:
      throw new Error("Unknown event type: " + event.type);
  }
};
