import { useRef } from "react";
import { formatDate, formatShortDate, formatWeekday } from "@utils/date.utils";
import { formatPrice } from "@utils/currency.utils";
import { downloadFile, openLink } from "@utils/utils";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { LessonType } from "@customTypes/LessonType";
import { Button } from "@components/Button";
import { MaterialPill } from "@components/MaterialPill";
import { LessonBulb } from "@components/LessonBulb";
import { PaymentStatus } from "@components/PaymentStatus";
import { CompletedHomeworkPill } from "@components/CompletedHomeworkPill";
import { ReactComponent as TrashImg } from "@img/trash.svg";
import { ReactComponent as AttachmentImg } from "@img/attachment.svg";
import { ReactComponent as RemoveImg } from "@img/remove.svg";
import cs from "./LessonDetailsPopover.module.scss";

type LessonDetailsPopoverContentProps = {
  lesson: LessonType;
  onDelete: () => void;
  onUploadHomework: (file: File) => void;
  onDeleteHomework: (homeworkId: string) => void;
};

export const LessonDetailsPopoverContent = ({
  lesson,
  onUploadHomework,
  onDeleteHomework,
  onDelete,
}: LessonDetailsPopoverContentProps) => {
  const fileInputElement = useRef<HTMLInputElement>(null);
  const popoverEl = useRef<any>();

  return (
    <div className={cs.LessonDetails} ref={popoverEl}>
      <div className={cs.header}>Spotkanie</div>
      <div className={cs.info}>
        <span className={cs.label}>Tutor:</span>
        <span>{lesson.tutor.name}</span>

        <span className={cs.label}>Data:</span>
        <span>
          <LessonBulb
            past={lesson.isPast}
            size="small"
            className="mr4"
            top={-2}
          />

          <span className="semi-bold">{formatShortDate(lesson.date)}</span>

          <span> ({formatWeekday(lesson.date, "long")})</span>
        </span>

        <span className={cs.label}>Godzina:</span>
        <span>
          <span className="semi-bold">
            {lesson.startHourStr} - {lesson.endHourStr}
          </span>
          <span> ({lesson.duration} minut)</span>
        </span>

        <span className={cs.label}>Temat:</span>
        <span>{lesson.topic || "-"}</span>

        <div className={cs.label}>
          <div>
            Zad. <br /> domowe:
          </div>

          {lesson?.homeworks.length > 0 && (
            <button
              className={cs.downloadLink}
              type="button"
              onClick={() => {
                lesson?.homeworks.forEach((homework) => {
                  if (homework.type !== "link") {
                    downloadFile(homework.link, popoverEl.current);
                  } else {
                    openLink(homework.link, popoverEl.current);
                  }
                });
              }}
            >
              Pobierz
            </button>
          )}
        </div>

        <span className={cs.materials}>
          {lesson.homeworks.length === 0 && "-"}

          {lesson.homeworks.map((material) => (
            <MaterialPill
              key={material._id}
              material={material}
              config={{ hideHomeworkInfo: true, color: "orange" }}
            />
          ))}
        </span>

        <div className={cs.label}>
          <div>Materiały:</div>

          {lesson?.materials.length > 0 && (
            <button
              className={cs.downloadLink}
              type="button"
              onClick={() => {
                lesson?.materials.forEach((material) => {
                  if (material.type !== "link") {
                    downloadFile(material.link, popoverEl.current);
                  } else {
                    openLink(material.link, popoverEl.current);
                  }
                });
              }}
            >
              Pobierz
            </button>
          )}
        </div>
        <span className={cs.materials}>
          {lesson.materials.length === 0 && "-"}

          {lesson.materials.map((material) => (
            <MaterialPill key={material._id} material={material} />
          ))}
        </span>

        <span className={cs.label}>Finanse:</span>
        <span>
          <span className="semi-bold">
            {lesson.price ? formatPrice(lesson.price) : "-"}
          </span>

          {/* TODO: */}
          {lesson.arrears[0].status === LessonPaymentStatus.AWAITS && (
            <span> ({formatDate(lesson.paymentDeadline)})</span>
          )}

          <PaymentStatus
            arrears={lesson.arrears}
            size="small"
            className="ml4"
          />
        </span>

        <span className={cs.label}>Opis lekcji:</span>
        <span dangerouslySetInnerHTML={{ __html: lesson.desc || "-" }} />
      </div>

      <div className="mb20 mt8">
        <div className={cs.homeworksHeader}>Odeślij zadania domowe:</div>

        <div className={cs.materials}>
          {lesson.completedHomeworks.length === 0 && "-"}

          {lesson.completedHomeworks.map((homework) => (
            <div className={cs.homework}>
              <CompletedHomeworkPill key={homework._id} homework={homework} />

              <button
                type="button"
                className="btn-icon"
                onClick={() => onDeleteHomework(homework._id)}
              >
                <RemoveImg width={12} />
              </button>
            </div>
          ))}
        </div>

        <Button
          color="primary"
          className={cs.uploadButton}
          onClick={() => {
            fileInputElement.current?.click();
          }}
        >
          <AttachmentImg width={14} className="mr8" />

          <span>Kliknij aby wybrać z dysku</span>

          <input
            type="file"
            multiple
            className={cs.fileInput}
            ref={fileInputElement}
            onChange={(e) => {
              if (!e.target.files) return;

              if (e.target.files?.length > 0) {
                Array.from(e.target.files).forEach((file) => {
                  onUploadHomework(file);
                });
              }

              e.target.value = "";
            }}
          />
        </Button>
      </div>

      <div className={cs.footer}>
        <button className="btn-icon" onClick={onDelete}>
          <TrashImg />
        </button>
      </div>
    </div>
  );
};
