import { useMutation, useQueryClient } from "react-query";
import { TutorApi } from "@api/TutorApi";

export const useDeleteTutor = () => {
  const queryClient = useQueryClient();

  return useMutation("tutors", TutorApi.deleteById, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["tutors"],
        refetchInactive: true,
      });
    },
  });
};
