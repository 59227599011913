import { useState } from "react";
import { useQuery } from "react-query";
import { LessonApi } from "@api/LessonApi";

export const useLessons = (filters: any, sort: any) => {
  const [page, setPage] = useState(1);

  const { data, isLoading } = useQuery(
    ["lessons", page, sort, filters],
    () => {
      return LessonApi.fetchAll(filters, sort);
    },
    {
      staleTime: 3e4,
      keepPreviousData: true,
    },
  );

  return {
    data,
    isLoading,
  };
};
