import React from "react";
import { Box } from "./Box";
import { ReactComponent as LogoImg } from "./img/logo.svg";
import cs from "./Page.module.scss";

type PageProps = {
  children: React.ReactNode;
};

export const Page = ({ children }: PageProps) => {
  return (
    <main className={cs.root}>
      <LogoImg width={200} />

      {children}
    </main>
  );
};

Page.Box = Box;
