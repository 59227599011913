import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoginPage } from "@pages/auth/LoginPage";
import { RegisterPage } from "@pages/auth/RegisterPage";
import { ResetPasswordPage } from "@pages/auth/ResetPasswordPage";
import { NewPasswordPage } from "@pages/auth/NewPasswordPage";
import { SharedLessonPage } from "@pages/SharedLessonPage";
import { MyTutorsPage } from "@pages/MyTutorsPage";
import { LessonsListPage } from "@pages/LessonsListPage";
import { FinancesListPage } from "@pages/FinancesListPage";
import { Settings } from "@pages/Settings";
import { CalendarPage } from "@pages/CalendarPage";
import { RequireAuth } from "./RequireAuth";
import { Layout } from "./Layout";
import { InvitationPage } from "@pages/auth/InvitationPage";

export const ApplicationRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/invitation/:invitationId" element={<InvitationPage />} />
      <Route path="/remind-password" element={<ResetPasswordPage />} />
      <Route path="/new-password/:token" element={<NewPasswordPage />} />
      <Route path="/shared-lesson/:lessonId" element={<SharedLessonPage />} />

      <Route
        path="/"
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route index element={<CalendarPage />} />
        <Route path="tutors" element={<MyTutorsPage />} />
        <Route path="lessons" element={<LessonsListPage />} />
        <Route path="finances" element={<FinancesListPage />} />
        <Route path="settings" element={<Settings />} />
        <Route path="*" element={<div>Strona jeszcze nie istnieje</div>} />
      </Route>
    </Routes>
  </BrowserRouter>
);
