import React from "react";
import classNames from "classnames";
import { Modal as ModalBase } from "react-responsive-modal";
import { Popover } from "../Popover";
import { Spinner } from "../Spinner";
import "react-responsive-modal/styles.css";
import "./Modal.global.scss";
import cs from "./Modal.module.scss";

// TODO: Question mark as icon.

type ModalProps = {
  isOpen: boolean;
  center?: boolean;
  title?: string;
  help?: string;
  className?: string;
  children: React.ReactNode;
  footer?: React.ReactNode;
  isLoading?: boolean;
  clickOutsideToClose?: boolean;
  onClose: () => void;
};

export const Modal = ({
  isOpen,
  children,
  footer = null,
  center = true,
  title,
  help,
  className,
  isLoading = false,
  clickOutsideToClose = true,
  onClose,
}: ModalProps) => {
  const modalCs = classNames(cs.root, className);

  return (
    <ModalBase
      open={isOpen}
      center={center}
      showCloseIcon={false}
      classNames={{
        modal: modalCs,
      }}
      closeOnOverlayClick={clickOutsideToClose}
      onClose={onClose}
    >
      <Spinner loading={isLoading}>
        <div>
          <header className={cs.topBar}>
            {title ? <span className={cs.title}>{title}</span> : <span />}

            <span className={cs.actions}>
              {help && (
                <Popover
                  position={["bottom"]}
                  popover={<div className={cs.desc}>{help}</div>}
                >
                  <span className={cs.action} onClick={onClose}>
                    ?
                  </span>
                </Popover>
              )}

              <span className={cs.action} onClick={onClose}>
                X
              </span>
            </span>
          </header>

          <main className={`ModalContent ${cs.content}`}>{children}</main>

          {footer && <footer className={cs.footer}>{footer}</footer>}
        </div>
      </Spinner>
    </ModalBase>
  );
};
