type ColumnType = {
  onDeleteTutor: (tutorId: string) => void;
};

export const columnsMobile = ({ onDeleteTutor }: ColumnType) => [
  {
    title: "Imię i nazwisko",
    field: "name",
  },
  {
    title: "Kontakt",
    style: { wordBreak: "break-word" },
    render(field, col, row) {
      return (
        <div>
          <div
            style={{
              wordBreak: "break-word",
              overflow: "hidden",
              whiteSpace: "break-spaces",
            }}
          >
            {row.email || "-"}
          </div>
          <div className="mt6">{row.phone || "-"}</div>
        </div>
      );
    },
  },
];
