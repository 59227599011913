import React, { useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { SharedLessonApi } from "@api/SharedLessonApi";
import { ToastService } from "@services/ToastService";
import { downloadFile, openLink } from "@utils/utils";
import { formatPrice } from "@utils/currency.utils";
import { formatDate, formatShortDate, formatWeekday } from "@utils/date.utils";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { useMarkSharedLessonAsPaid } from "@hooks/useMarkSharedLessonAsPaid";
import { useSharedLesson } from "@hooks/useSharedLesson";
import { useCancelSharedLesson } from "@hooks/useCancelSharedLesson";
import { LessonType } from "@customTypes/LessonType";
import { Button } from "@components/Button";
import { Spinner } from "@components/Spinner";
import { InfoBox } from "@components/InfoBox";
import { MaterialPill } from "@components/MaterialPill";
import { CompletedHomeworkPill } from "@components/CompletedHomeworkPill";
import { Page } from "../auth/components/Page";
import { ReactComponent as RemoveImg } from "@img/remove.svg";
import { ReactComponent as AttachmentImg } from "@img/attachment.svg";
import cs from "./SharedLessonPage.module.scss";

export const SharedLessonPage = () => {
  const fileInputElement = useRef<HTMLInputElement>(null);

  const markSharedLessonAsPaid = useMarkSharedLessonAsPaid();
  const cancelSharedLesson = useCancelSharedLesson();

  const params = useParams();

  const {
    data: lesson,
    isLoading,
    refetch,
  } = useSharedLesson(params.lessonId as string);

  if (isLoading) {
    return <Spinner loading className="fullViewSpinner" size={54} />;
  }

  if (!lesson) return null;

  return (
    <Page>
      <div className={cs.box}>
        <header>
          <div className={cs.pageTitle}>
            <div>Lekcja</div>
            <div className={cs.pageTitle_when}>
              <span>{formatShortDate(lesson.date)}</span>
              <span>
                , {lesson.startHourStr}-{lesson.endHourStr}
              </span>
            </div>

            {lesson.isDeleted && (
              <div className={cs.lessonIsCancelledInfo}>
                Lekcja została odwołana
              </div>
            )}
          </div>
        </header>

        <section className={cs.lessonDetails}>
          <div className={cs.lessonDetails_side}>
            <div className={cs.lessonDetails_group}>
              <div className={cs.lessonDetails_info__lg}>
                <div className={cs.title}>Data</div>
                <div>
                  <span>{formatDate(lesson.date)}</span>

                  <span>, {formatWeekday(lesson.date, "long")}</span>
                </div>
              </div>
              <div className={cs.lessonDetails_info__lg}>
                <div className={cs.title}>Godzina</div>
                <div>
                  {lesson.startHourStr}-{lesson.endHourStr}, {lesson.duration}{" "}
                  minut
                </div>
              </div>
            </div>

            <div className={cs.lessonDetails_group}>
              <div className={cs.lessonDetails_info}>
                <div className={cs.title}>Korepetytor</div>
                <div>{lesson.tutor.name}</div>
              </div>

              <div className={cs.lessonDetails_info}>
                <div className={cs.title}>Uczeń</div>
                <div>{lesson.participant.details.name}</div>
              </div>
            </div>

            <div className={cs.lessonDetails_group}>
              <div className={cs.lessonDetails_info}>
                <div className={cs.title}>Cena</div>
                <div>{lesson.price ? formatPrice(lesson.price) : "-"}</div>
              </div>

              <div className={cs.lessonDetails_info}>
                <div className={cs.title}>Termin płatności</div>
                <div>
                  {lesson.paymentDeadline
                    ? formatDate(lesson.paymentDeadline)
                    : "-"}
                </div>
              </div>

              <div className={cs.lessonDetails_info}>
                <div className={cs.title}>Status</div>
                <div>{getStatusInfo(lesson)}</div>
              </div>
            </div>
          </div>

          <div className={cs.lessonDetails_side}>
            <div className={cs.lessonDetails_info__lg}>
              <div className={cs.title}>Temat</div>
              <div>{lesson.topic}</div>
            </div>

            <div className={cs.lessonDetails_info}>
              <div className={cs.title}>Materiały</div>
              <div>
                {lesson?.materials.length > 0 && (
                  <button
                    className={cs.downloadLink}
                    type="button"
                    onClick={() => {
                      lesson?.materials.forEach((material) => {
                        if (material.type !== "link") {
                          downloadFile(material.link);
                        } else {
                          openLink(material.link);
                        }
                      });
                    }}
                  >
                    Pobierz wszystkie
                  </button>
                )}
              </div>
              <div>
                <span className={cs.materials}>
                  {lesson.materials.length === 0 && "-"}

                  {lesson.materials.map((material) => (
                    <MaterialPill key={material._id} material={material} />
                  ))}
                </span>
              </div>
            </div>

            <div className={cs.lessonDetails_info}>
              <div className={cs.title}>Zadania domowe</div>
              <div>
                {lesson?.homeworks.length > 0 && (
                  <button
                    className={cs.downloadLink}
                    type="button"
                    onClick={() => {
                      lesson?.homeworks.forEach((homework) => {
                        if (homework.type !== "link") {
                          downloadFile(homework.link);
                        } else {
                          openLink(homework.link);
                        }
                      });
                    }}
                  >
                    Pobierz wszystkie
                  </button>
                )}
              </div>

              <div>
                <span className={cs.materials}>
                  {lesson.homeworks.length === 0 && "-"}

                  {lesson.homeworks.map((material) => (
                    <MaterialPill
                      key={material._id}
                      material={material}
                      config={{ hideHomeworkInfo: true, color: "orange" }}
                    />
                  ))}
                </span>
              </div>
            </div>

            <div className={cs.lessonDetails_info}>
              <div className={cs.title}>Opis lekcji</div>
              <div dangerouslySetInnerHTML={{ __html: lesson.desc || "-" }} />
            </div>
          </div>
        </section>

        <section className={cs.completedHomeworksSection}>
          <div className={cs.uploadCompletedHomework}>
            <div className={`${cs.title} mb6`}>Odeślij zadania domowe</div>

            <div className={cs.materials}>
              {lesson.completedHomeworks.length === 0 && "-"}

              {lesson.completedHomeworks.map((homework) => (
                <div key={homework._id} className={cs.homework}>
                  <CompletedHomeworkPill homework={homework} />

                  <button
                    type="button"
                    className="btn-icon"
                    title="Usuń"
                    onClick={() => handleDeleteHomework(homework._id)}
                  >
                    <RemoveImg width={12} />
                  </button>
                </div>
              ))}
            </div>

            <Button
              color="primary"
              className={cs.uploadButton}
              onClick={() => {
                fileInputElement.current?.click();
              }}
            >
              <AttachmentImg width={14} className="mr8" />

              <span>Kliknij aby wybrać z dysku</span>

              <input
                type="file"
                multiple
                className={cs.fileInput}
                ref={fileInputElement}
                onChange={(e) => {
                  if (!e.target.files) return;

                  if (e.target.files?.length > 0) {
                    Array.from(e.target.files).forEach((file) => {
                      handleUploadHomework(file);
                    });
                  }

                  e.target.value = "";
                }}
              />
            </Button>
          </div>
        </section>

        {!lesson.isDeleted && (
          <div className={cs.actions}>
            {lesson.arrears[0].status === LessonPaymentStatus.AWAITS && (
              <Button
                color="secondary"
                className={cs.submitBtn}
                isLoading={markSharedLessonAsPaid.isLoading}
                onClick={handleMarkAsPaid}
              >
                Oznacz jako opłaconą
              </Button>
            )}

            <Button
              color="danger"
              type="outlined"
              className={cs.submitBtn}
              isLoading={cancelSharedLesson.isLoading}
              onClick={handleCancelLesson}
            >
              Odwołaj
            </Button>
          </div>
        )}

        <div className={cs.noAccountText}>
          <Link to="/login" className="link">
            Przejdź do strony logowania
          </Link>

          <InfoBox desc="Poproś korepetytora o wysłanie zaproszenia do aplikacji. Konto ucznia jest zawsze darmowe. Umożliwia m.in. dostęp do swojego kalendarza lekcji, otrzymywanie i dostarczanie zadań domowych, otrzymywanie powiadomień, dostęp do historii spotkań oraz notatek." />
        </div>
      </div>
    </Page>
  );

  async function handleUploadHomework(file: File) {
    await SharedLessonApi.uploadHomework({
      lessonId: params.lessonId as string,
      file,
    });

    await refetch();
  }

  async function handleDeleteHomework(homeworkId: string) {
    if (!lesson) return;

    await SharedLessonApi.deleteHomeworkById({
      lessonId: params.lessonId as string,
      homeworkId,
    });

    await refetch();
  }

  async function handleCancelLesson() {
    await cancelSharedLesson.mutateAsync({
      lessonId: params.lessonId as string,
    });

    await refetch();

    ToastService.success(
      "Lekcja została odwołana. Powiadomiliśmy korepetytora.",
      { duration: 5000 },
    );
  }

  async function handleMarkAsPaid() {
    await markSharedLessonAsPaid.mutateAsync({
      lessonId: params.lessonId as string,
    });

    await refetch();

    ToastService.success("Lekcja została oznaczona jako opłacona.", {
      duration: 5000,
    });
  }
};

function getStatusInfo(lesson: LessonType) {
  const { status, isAfterDeadline } = lesson.arrears[0];

  switch (status) {
    case LessonPaymentStatus.AWAITS: {
      if (isAfterDeadline) {
        return "Upłynął termin płatności";
      }

      return "Oczekuje na płatność";
    }
    case LessonPaymentStatus.PAID:
      return "Oznaczona jako opłacona";
    case LessonPaymentStatus.CLOSED:
      return "Rozliczono";
  }
}
