import { StandaloneArrearDtoService } from "@services/StandaloneArrearDtoService";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { createAxiosInstance } from "./_createAxiosInstance";

const api = createAxiosInstance("/api/finances");

export class FinancesApi {
  static async getStandaloneArrears(
    filters: Record<string, any>,
    sort: string[],
  ) {
    const { data } = await api.get("/arrear", {
      params: { status: filters.status, sort },
    });

    return StandaloneArrearDtoService.transformAll(data);
  }

  static changePaymentStatus({
    arrearId,
    status,
  }: {
    arrearId: string;
    status: LessonPaymentStatus;
  }) {
    return api.patch("/arrear/" + arrearId, {
      status,
    });
  }
}
