import { useState } from "react";
import { useImmer } from "use-immer";
import { useStandaloneArrears } from "@hooks/useStandaloneArrears";
import { useChangeArrearStatus } from "@hooks/useChangeArrearStatus";
import { LessonPaymentStatus } from "@constants/LessonPaymentStatus";
import { Table } from "@components/Table";
import { Button } from "@components/Button";
import { Filters } from "./Filters";
import { FiltersModal } from "./FiltersModal";
import { columns } from "./columns";
import { ReactComponent as FiltersImg } from "@img/filters.svg";
import cs from "./FinancesListPage.module.scss";
import { columnsMobile } from "@pages/FinancesListPage/columnsMobile";
import { isSmallScreen } from "@utils/utils";

const FILTERS_INIT = {
  students: [],
  status: "all",
};

export const FinancesListPage = () => {
  const [filters, setFilters] = useImmer<Record<string, any>>(FILTERS_INIT);
  const [filtersModalOpen, setFiltersModalOpen] = useState(false);

  const [sort, handleSort] = Table.useSort();

  const { data = [], isLoading } = useStandaloneArrears(filters, sort);
  const changeStatus = useChangeArrearStatus();

  return (
    <main className={cs.page}>
      <header className={cs.header}>
        <div className={cs.openFiltersBtn}>
          <Button
            color="neutral"
            type="outlined"
            className="hide-desktop-up mr-auto"
            onClick={() => setFiltersModalOpen(true)}
          >
            <FiltersImg width={12} />
            <span>Filtruj</span>
          </Button>
        </div>
      </header>

      <Filters filters={filters} onChange={handleFilterChanged} />

      <section className={cs.table}>
        <Table
          data={data}
          sort={sort}
          columns={(isSmallScreen ? columnsMobile : columns)({
            onStatusChange: handleStatusChanged,
          })}
          isLoading={isLoading}
          onSort={handleSort}
        />
      </section>

      <FiltersModal
        isOpen={filtersModalOpen}
        filters={filters}
        onChange={handleFilterChanged}
        onClose={() => setFiltersModalOpen(false)}
      />
    </main>
  );

  function handleFilterChanged(filterKey: string, value: any) {
    setFilters((prev: any) => void (prev[filterKey] = value));
  }

  function handleStatusChanged(arrearId: string, status: LessonPaymentStatus) {
    changeStatus.mutate({ arrearId, status });
  }

  function handleClearFilters() {
    setFilters(FILTERS_INIT);
  }
};
